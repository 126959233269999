.social-share {
	margin-bottom: 30px;

	@media screen and (min-width: 64em) {
		margin: 0;
	}

	ul {
		max-width: 200px;
		margin: 0 auto;
		list-style: none;
		padding: 0;
	  	text-align: center;

		@media screen and (min-width: 64em) {
			max-width: none;
			margin: 0;
			position: absolute;
			left: -130px;
			top: -10px;
		}

		li {
			margin: 0 15px;
			display: inline-block;

			@media screen and (min-width: 64em) {
				margin: 16px 0 0 0;
				float: none;
				display: block;
			}

			&:not(:first-child) {
				border: 0;
				padding-left: 0;
				margin-left: 0;
			}

			span {
				height: 45px;
				width: 45px;
				border-radius: 30px;
				display: block;
				border: solid 1px $mid-grey;
				cursor: pointer;
				text-align: center;
				line-height: 40px;

				&:hover,
				&:focus {
					background-color: $link-colour;

					img {
						filter: brightness(100);
					}
				}

				img {
					width: 25px;
					height: auto;
					vertical-align: middle;
				}
			}

			&.Wechat{
			  position: relative;
			  display: none;
			  @media screen and (min-width: 64em) {
				display: block;
			  }
			  .wechat-qrcode{
				display: none;
				box-shadow: 0 2px 15px #888888;;
				transition: all 200ms;
				background: #fff;
				border-radius: 11px;
				position: absolute;
				left: 55px;
				top: 0px;
				z-index: 9;
				h6{
				  font-size: 12px;
				  margin-bottom: 10px;
				  box-shadow: 0 0 black;
				  text-align: center;
				  word-break: keep-all;
				  padding: 10px;
				  background: #f5f5f5;
				  border-radius: 10px 10px 0px 0px;
				}
				canvas{
				  margin: 0 auto 15px auto;
				  display: block;
				}
				&::after{
				  content: "";
				  position: absolute;
				  left: 0;
				  margin-left: -12px;
				  top: 25px;
				  width: 0;
				  height: 0;
				  border: 6px solid #63121200;
				  border-right: 7px solid #f5f5f5;
				}
			  }
			  &:hover{
				.wechat-qrcode{
				  display: block;
				}
			  }
			}



			*::-ms-backdrop,
			span:hover {
				color: $brand-white;
			}
		}
	}
}

@keyframes toFadeInUp {
	from {
		transform: translateY(100px);
		opacity: 0;
	}

	to {
		transform: none;
		opacity: 1;
	}
}

.toFadeInUp {
	animation-name: toFadeInUp;
	animation-fill-mode: inherit;
	animation-duration: 1.5s;
	animation-timing-function: ease-in-out;
}

/* Required so that SiteCore Experience manager display the content cards incorrectly */
a.content-card,
div.content-card {
	@media screen and (min-width: 64em) {
		height: 100%;
	}
}

.content-card {
	cursor: pointer;
	border: 1px solid $light-grey;
	position: relative;
	background-color: $brand-white;
	max-width: 1180px;
	margin: 0 auto 20px auto;
	display: block;
	text-decoration: none;
	outline: none;

	@media screen and (min-width: 64em) {
		@include transition(0.35s);
		position: relative;
		z-index: auto;
		overflow: visible;
		opacity: 1;
		transform: perspective(1000px) translate3d(0, 0, 0);

		&::before {
			@include transition(0.35s);
			opacity: 0;
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
			width: 100%;
			height: 100%;
			content: '';
		}
	}

	> a {
		text-decoration: none;
		display: block;
	}

	.content-container {
		margin: 0;
		background-color: $brand-white;
		display: block;

		@media screen and (min-width: 64em) {
			padding: 0 0 60px 0;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		p {
			margin: 0;
			border-left: 5px solid transparent;

			a {
				text-decoration: none;
			}
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			padding: 20px 30px 20px 30px;

			a {
				font-weight: 600;
			}
		}

		p {
			padding: 0 30px 40px 30px;
		}

		.cta-button {
			position: relative;
			display: block;
			border: 0;
			border-top: 1px solid $light-grey;
			border-left: 5px solid $cochlear-yellow;
			background-color: $brand-white;
			color: $link-colour;
			font-size: 18px;
			font-weight: bold;
			text-align: left;
			padding-left: 30px;
			margin-top: 0;
			line-height: 30px;
			border-radius: 0;

			@media screen and (min-width: 64em) {
				z-index: 1;

				position: absolute;
				bottom: 0;
				width: 100%;

				&::before {
					content: '';
					width: 0;
					position: absolute;
					left: 0;
					bottom: 0;
					height: 100%;
					z-index: -1;
				}
			}

			&::after {
				@include transition(0.2s);
				background-image: sax(Chevron, $cochlear-purple);
			}
		}

		&.no-image {
			.content__grouper {
				float: left;
				width: 100%;
			}
		}
	}

	.image {
		img {
			opacity: 1;
		}
	}

	.img-container {
		position: relative;
		height: 215px;
		overflow: hidden;

		&.is-video-article {
			@include video-play-icon;
		}

		@media screen and (min-width: 64em) {
			margin-bottom: 20px;
		}

		img,
		.img {
			width: 100%;
			height: auto;
			margin: 0;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);

			@media screen and (min-width: 64em) {
				width: 150%;
				position: relative;
				top: 0;
				left: 50%;
				transform: translate(-50%, 0);
			}

			@media screen and (min-width: 77.75em) {
				width: auto;
				height: 215px;
			}
		}

		&:hover {
			img,
			.img {
				filter: grayscale(0);

				@media screen and (min-width: 64em) {
					filter: grayscale(0);
				}
			}
		}

		&::after {
			background-color: transparent;

			@media screen and (min-width: 64em) {
				background-color: transparent;
			}
		}

		.image {
			&::after {
				background-color: transparent;

				@media screen and (min-width: 64em) {
					background-color: transparent;
				}
			}
		}
	}

	&:focus .cta-button {
		@include mimic-default-browser-focus;
	}

	&:hover,
	&:focus {
		.content-container {
			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				color: $link-colour;
			}
		}
	}

	&:hover,
	&:focus,
	&:active {
		.content-container .cta-button::after {
			right: 16px;
		}
	}

	&:hover {
		@media screen and (min-width: 64em) {
			transform: perspective(1000px) translate3d(0, 0, 21px);
			box-shadow: 0 3px 30px rgba(0, 0, 0, 0.3);
			transition: all 0.2s ease-in-out;

			&::before {
				opacity: 1;
				z-index: 0;
			}

			.cta-button {
				&::before {
					width: 100%;
				}
			}

			.img-container {
				z-index: 1;
			}

			.content-container {
				z-index: 1;
			}
		}
	}

	&:last-child {
		margin-bottom: 20px;

		@media only screen and (min-width: 64em) {
			margin-bottom: 0;
		}
	}
}

.is-stretch {
	.img-container {
		img {
			width: 140%;
			margin: 0 -20%;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);

			@media only screen and (min-width: 48em) {
				width: 100%;
				margin: auto;
				height: auto;
			}

			@media only screen and (min-width: 77.75em) {
				height: auto;
				width: 100%;
				margin: auto;
			}
		}
	}

	.cta-button {
		width: 100%;
		bottom: 0;

		@media only screen and (min-width: 77.75em) {
			position: absolute;
			width: 50%;
			bottom: 0;
		}
	}
}

// Handle the 66 split of the content cards
.sl {
	&.is-66-split {
		.sl-item {
			@media only screen and (min-width: 64em) {
				flex: auto;
			}

			&:first-child {
				@media only screen and (min-width: 64em) {
					width: 66%;
				}

				.img-container {
					@media only screen and (min-width: 77.5em) {
						float: left;
						width: 50%;
						height: 100%;
						margin-bottom: 0;
						position: relative;

						img {
							height: 100%;
							width: auto;
							top: 0;
							position: absolute;
						}
					}
				}

				.content-container {
					@media only screen and (min-width: 77.5em) {
						float: left;
						width: 50%;
					}

					.cta-button {
						@media only screen and (min-width: 77.5em) {
							width: 50%;
							position: absolute;
							bottom: 0;
						}
					}
				}
			}

			&:last-child {
				@media only screen and (min-width: 64em) {
					width: 33%;
				}
			}

			@media only screen and (min-width: 64em) {
				.img-container {
					@media only screen and (min-width: 77.5em) {
						height: 270px;

						img {
							width: 140%;
							margin: 0 -20%;
							height: auto;
						}
					}
				}
			}
		}
	}

	.sl-list {
		@media only screen and (min-width: 64em) {
			display: flex;
		}
	}
}

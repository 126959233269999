.nav-onscreen {
	.nav {
		overflow: scroll;
		height: 100vh;
		margin: 0;
		padding: 0;
		background-color: $light-grey;
		list-style: none;
		position: relative;
    z-index: 4;

    &.cf {
      &:not(.is-hidden) {
        .nav-item-top {
          visibility: visible;
        }
      }

      &.is-hidden {
        @include mobile-menu-background-visibility;
      }
    }

		@media only screen and (min-width: 64em) {
			height: auto;
			overflow: auto;
			background-color: transparent;
		}

		.nav-children {
			margin: 0;
			padding: 0;
		}

		.nav-item {
			margin-top: 0;
			background-color: $brand-white;
			list-style: none;
      padding: 0;

			@media only screen and (min-width: 64em) {
        background-color: transparent;

        .nav-item-title {
          outline: 0;
        }
			}

			.nav-item-title {
				background-color: transparent;
				border: 0;
        font-family: $font-primary;
        width: 100%;
				max-width: 100vw; // chromeVox accessibility test
				text-align: left;
				text-decoration: none;
				display: block;
				padding: 20px;
				color: $link-colour;
				line-height: normal;
				position: relative;
				border-left-color: transparent;
				cursor: pointer;
				border-bottom: solid 1px $mid-grey;

				@media only screen and (min-width: 64em) {
					font-size: 16px;
					padding: 16px 0 16px 19px;
					color: $cool-grey;
				}

				&:hover,
				&:focus,
				&:active {
					@media only screen and (min-width: 64em) {
						padding-left: 14px;
						border-left: 5px solid $cochlear-yellow;
						color: $cool-grey;
						background-color: rgba(204, 204, 204, 0.2);
					}
				}

				&.nav-item-title-top {
					@media only screen and (min-width: 64em) {
						padding: 48px 27px 48px 10px;
						margin-left: 0;
						color: $cool-grey;
						font-size: 16px;
						font-family: $font-primary-bold;
						border-bottom: 0;
					}

					@media only screen and (min-width: 77.75em) {
						padding-right: 42px;
						padding-left: 22px;
					}

					&::before {
						@media only screen and (min-width: 64em) {
							content: '';
							transition: 0.3s;
							width: calc(100% - 40px);
							margin-left: -10px;
							height: 2px;
							background-color: $cochlear-yellow;
							position: absolute;
							transform: translateX(-50%);
							left: 50%;
							bottom: 40px;
							opacity: 0;
						}
					}

					&:hover,
					&:focus,
					&:active {
						@media only screen and (min-width: 64em) {
							color: $cochlear-purple;
							border: 0;
							background-color: transparent;
						}

						&::before {
							@media only screen and (min-width: 64em) {
								color: $cool-grey;
								opacity: 1;
							}
						}
					}

					&.has-children {
						&::after {
							@media only screen and (min-width: 64em) {
								background-image: sax(Chevron, $cochlear-yellow);
								transform: rotate(0deg);
								right: 4px;
							}

							@media only screen and (min-width: 77.75em) {
								right: 16px;
							}
						}
					}
				}

				&.has-children {
					&::after {
						content: '';
						width: 20px;
						height: 20px;
						position: absolute;
						background-image: sax(Chevron, $cochlear-purple);
						background-size: 100% 100%;
						right: 8px;
						transform: rotate(-90deg);
					}
				}
			}
		}

		.nav-item-selected {
      #nav-main-1 {
        visibility: visible;
      }

			.nav-item-title-top {
				@media only screen and (min-width: 64em) {
					&:before {
						opacity: 1;
					}
				}
			}
		}

		.nav-item-top {
			height: 70px;
			padding: 0 10px;
			background-color: $cochlear-black;
			position: fixed;
      width: 100%;
      max-width: 100vw;
      z-index: 1;

      &.open {
        visibility: visible;
      }

			@media only screen and (min-width: 64em) {
				display: none;
			}

			+ .nav-item {
				margin-top: 70px;

				@media only screen and (min-width: 64em) {
					margin-top: 0;
				}
			}

			.nav-item-back,
			.nav-item-menu,
			.nav-item-close {
				float: left;
				background-color: transparent;
				line-height: 70px;
				border: 0 !important;
				font-size: 20px;
				color: $brand-white;
				position: relative;
        padding: 0;

        @media only screen and (min-width: 64em) {
          outline: 0;
        }
			}

			.nav-item-back {
				padding-left: 29px;
				cursor: pointer;

				&::before {
					content: '';
					background-image: sax(Chevron, $brand-white);
					left: 0;
					top: 20px;
					position: absolute;
					transform: rotate(90deg);
					height: 30px;
					width: 30px;
					display: block;
					background-size: 100%;
				}
			}

			.nav-item-close {
				float: right;
				border-bottom: 0;
				width: 60px;
				text-indent: -999em;
				background-image: sax(Close, $brand-white);
				background-repeat: no-repeat;
				background-position: center center;
				background-size: 20px;
			}
		}

		.nav-item-home {
			display: block;
		}
	}

	.additional-list {
		ul {
			padding: 0 0 0 20px;

			li {
				list-style: none;
				padding: 0;

				a {
					text-decoration: none;
				}
			}
		}
	}

	&.mobile-menu {
		display: block;
		float: none;
		top: 0;
		padding-top: 0;
		height: calc(100vh - 65px);

		@media only screen and (min-width: 64em) {
			display: none;
		}

		.logo {
			display: none;
		}

		@keyframes slideMobileIn {
			100% {
				right: 0;
				opacity: 1;
			}
		}

		@keyframes slideMobileOut {
			100% {
				left: 0;
				opacity: 0;
			}
		}

		#nav-main-1 .menu-level-detail,
		#nav-main-1 .nav-item-submenu {
			right: -230px;
			left: auto;
			animation: slideMobileIn 0.3s forwards;
			animation-timing-function: ease-in;
			z-index: 2;
		}

		.animate-out {
			#nav-main-1 .menu-level-detail,
			#nav-main-1 .nav-item-submenu {
				opacity: 1;
			}
		}
	}

	&.is-sticky {
		.nav .nav-item .nav-item-title.nav-item-title-top {
			padding: 28px 27px 28px 10px;

			@media only screen and (min-width: 77.75em) {
				padding: 28px 42px 28px 22px;
			}

			&::before {
				bottom: 20px;
			}
		}

		.search-toggle {
			margin: 17px 0 17px 24px;
		}
	}

	#nav-main-1 {
		@keyframes fadeInOpacity {
			0% {
				opacity: 0;
			}

			100% {
				opacity: 1;
			}
		}

		@media only screen and (min-width: 64em) {
			transition: all 0.3s;
			position: fixed;
			width: 100%;
			height: auto;
			left: 0;
			right: auto;
			top: auto;
			background-image: linear-gradient(to left, $light-grey, $light-grey 50%, white 50%);
			background-repeat: no-repeat;
			animation-name: fadeInOpacity;
			animation-iteration-count: 1;
			animation-timing-function: ease-in;
			animation-duration: 0.2s;
			z-index: 1;
		}

		&:after {
			@media only screen and (min-width: 64em) {
				content: '';
				// position: absolute;
				// width: 100%;
				// height: 1000%;
				// top: 100%;
				// z-index: 2;
				// background-color: rgba(0, 0, 0, 0.7);
			}
		}

		&:before {
			@media only screen and (min-width: 64em) {
				content: '';
				position: absolute;
				width: 100%;
				height: 5px;
				top: -5px;
				z-index: 2;
				box-shadow: 3px 4px 5px 0 rgba(0, 0, 0, 0.2);
			}
		}

		.menu-level-detail,
		.nav-item-submenu {
			background-color: $brand-white;
			position: fixed;
			z-index: 1;
			top: 70px;
			width: 100%;
			height: auto;
			padding: 0;
			left: 0;
			list-style-type: none;
			bottom: 0;
			overflow-y: scroll;
			overflow-x: hidden;
			margin: 0 0 65px 0;

			@keyframes slideDesktopIn {
				100% {
					right: 0;
					opacity: 1;
				}
			}

			@media only screen and (min-width: 64em) {
				position: relative;
				top: 0;
				background-color: transparent;
				right: auto;
				width: 50%;
				height: auto;
				float: left;
				opacity: 0;
				animation: slideDesktopIn 0.3s forwards;
				animation-delay: 0.1s;
				margin: 0;
				overflow: auto;
			}

			.header-link-container {
				position: relative;
				display: block;
				text-decoration: none;
				border-bottom: 5px solid $cochlear-yellow;

				@media only screen and (min-width: 64em) {
					border-bottom: 1px solid $mid-grey;
					padding-right: 110px;
				}

				&::after {
					content: '';
					width: 20px;
					height: 20px;
					position: absolute;
					background-image: sax(Arrow, $cochlear-purple);
					background-size: 100% 100%;
					right: 12px;
					margin-top: -20px;
					top: 50%;

					@media only screen and (min-width: 64em) {
						display: none;
					}
				}

				h3 {
					margin: 20px;
				}
			}

			.cta-link {
				display: none;

				@media only screen and (min-width: 64em) {
					display: block;
					font-weight: 700;
					font-size: 18px;
					color: $cochlear-purple;
					position: absolute;
					top: 0;
					right: 10px;
					line-height: 35px;

					&::after {
						content: '';
						width: 20px;
						height: 20px;
						display: inline-block;
						background-image: sax(Chevron, $cochlear-purple);
						background-size: 100%;
						transform: rotate(-90deg);
						top: 5px;
						position: relative;
						left: 5px;
					}

				  &.no-link{
					&::after {
					  display: none;
					}
				  }
				}
			}

			.nav-description {
				display: none;
			}
		}

		.menu-level-detail {
      &.is-hidden {
        @include mobile-menu-background-visibility;
      }

			@media only screen and (min-width: 64em) {
				padding: 50px 50px 120px 0;
			}
		}

		@keyframes slideSubmenu {
			0% {
				left: -50px;
				opacity: 0;
			}

			100% {
				left: 0;
				opacity: 1;
				transition: left 0.5s ease, right 0.5s ease, opacity 0.5s ease;
				position: relative;
			}
		}

		.nav-item-submenu {
			@media only screen and (min-width: 64em) {
				padding: 50px 50px 80px;

				.detail-content-container {
					position: relative;
					left: -50px;
					opacity: 0;
					animation: slideSubmenu 0.3s forwards;
					animation-delay: 0.1s;
				}
			}
		}

		.nav-lvl2-container {
			position: relative;
		}

		.nav-item-close {
			display: none;

			@media only screen and (min-width: 64em) {
				position: absolute;
				z-index: 1;
				display: block;
				top: 15px;
				border: 0;
				opacity: 0.5;
				right: 0;

				width: 40px;
				height: 40px;
				background-image: sax(Close, $cool-grey);
				background-color: transparent;
				background-size: 15px 15px;
				background-position: center;
				background-repeat: no-repeat;

				&:hover {
					opacity: 1;
				}
			}
		}
	}

	.offscreen-background {
		display: none;

		@media only screen and (min-width: 64em) {
			display: block;
			transition: all 0.5s;
			position: fixed;
			background-color: rgba(0, 0, 0, 0);
			width: calc(100vw - (100vw - 100%));
			// height: calc(100vh - 100%);
			// top: 100%;
			height: calc(100vh - 165px);
		  	top: 235px;
			z-index: 2;

			&.is-expanded {
				background-color: rgba(0, 0, 0, 0.7);
			}
		}
	}

    &.is-sticky{
	  .offscreen-background {
		@media only screen and (min-width: 64em) {
		  top: 165px;
		}
	  }
	}
}

.professionals-page {
	.nav-onscreen .nav .nav-item .nav-item-title .nav-item-title-top {
		&.has-children:after {
			background-image: sax(Chevron, $cochlear-purple);
		}

		:before {
			background-color: $cochlear-purple;
		}
	}
}

.animate-out {
	@keyframes slideSubmenuRight {
		0% {
			left: 0;
			opacity: 1;
		}

		100% {
			left: -50px;
			opacity: 0;
			transition: right 0.5s ease, left 0.5s ease, opacity 0.5s ease;
			position: relative;
		}
	}

	#nav-main-1 {
		.nav-item-submenu {
			@media only screen and (min-width: 64em) {
				padding: 50px 50px 80px;

				.detail-content-container {
					// opacity: 1;
					position: relative;
					// left: -50px;
					animation: slideSubmenuRight 0.3s forwards;
					// animation-delay: 0.1s;
				}
			}
		}
	}
}

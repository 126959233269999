.product-testimonial-only-text {
	background-color: $light-grey;
	padding: 30px 0;

	@media only screen and (min-width: 64em) {
		padding: 60px 0;
	}

	table {

		margin: 0;

		tr {
			background-color: transparent;

			&:nth-child(2n) {
				td {
					background-color: transparent;
					border: 0;
				}
			}
		}

		td {
			border: 0;
			width: 100%;
			display: block;
			padding: 10px 0;
			background-color: transparent;
			vertical-align: top;

			h4 {
				@extend .h3;
				font-family: $font-primary;
				margin-bottom: 0;
			}

			p {
				margin-top: 0;
			}

			&:nth-child(2n) {
				background-color: transparent;
			}

			@media only screen and (min-width: 64em) {
				display: table-cell;
				width: 50%;
				padding: 0 70px 30px 0;

				h4 {
					margin-bottom: 20px;
				}
			}
		}
	}

	.product-testimonial-container {
		display: flex;
		flex-direction: column;

		.content-container {
			margin-top: 20px;

			.scWebEditInput.scEnabledChrome[contentEditable="true"] {
				width: 100%;
			}

			@media only screen and (min-width: 64em) {
				margin-top: 60px;
			}

			a {
				color: $link-colour;
			}

			.container-group {
				display: flex;
				flex-wrap: wrap;
				list-style: none;
				margin: 0;
				padding: 0;

				.container-item {
					width: 100%;
					margin: 0 0 40px;

					@media only screen and (min-width: 64em) {
						width: 50%;
						padding-right: 70px;
					}

					.h3 {
						display: block;

						* {
							font-size: 28px;
							font-weight: 700;

							sup {
								font-size: 20px;
							}
						}
					}

					ul {
						padding-left: 20px;
						margin: 0;

						li {
							list-style: disc;
						}
					}
				}
			}
		}
	}

	&.is-secondary {
		background-color: $brand-white;
	}
}

.schematik-form {
  hr {
    &:last-of-type {
      &.show {
        &.horizontal-rule {
          display: none;
        }
      }
    }
  }

  // fieldset.form-box:nth-last-child(2) {
  //     padding-bottom: 0;
  // }
  .error-summary-container {
    padding-top: 0;
    padding-bottom: 0;

    .details-box {
      margin-top: 20px;

      .error-summary {
        margin-top: 0;
      }
    }
  }

  .render-buttons {
    padding-top: 0;
  }

  .form-box .sl-list .multi-container .date-input:last-child::after {
    content: '';
  }

  @media only screen and (min-width: 64em) {
    .split-group {
      display: flex;

      label {
        line-height: 30px;
      }

      .split-ratio-1 {
        flex: 0 1 100%;

        &:not(:last-child) {
          margin-right: 30px;
        }
      }

      .split-ratio-2 {
        flex: 0 2 100%;

        &:not(:last-child) {
          margin-right: 30px;
        }
      }

      .split-ratio-3 {
        flex: 0 3 100%;

        &:not(:last-child) {
          margin-right: 30px;
        }
      }

      .split-ratio-4 {
        flex: 0 4 100%;
        
        &:not(:last-child) {
          margin-right: 30px;
        }
      }

      .split-ratio-5 {
        flex: 0 5 100%;
        
        &:not(:last-child) {
          margin-right: 30px;
        }
      }

      .split-ratio-6 {
        flex: 0 6 100%;
        
        &:not(:last-child) {
          margin-right: 30px;
        }
      }

      .sl-item {
        select {
          padding: 5px 10px;
        }
      }
    }
  }

  .field-container {
    fieldset {
      legend {
        font-size: 16px;
        margin-top: 30px;
        color: $cool-grey;
        font-family: "BlissPro-Light", "Trebuchet MS", "Gill Sans", "Helvetica Neue", Arial, sans-serif;
        font-weight: bold;
        width: 100%;

        select,
        input {
          line-height: 20px;
        }

        .split-group {
          .sl-item {
            .select-input {
              select {
                padding: 10px;
              }
            }
          }
        }
        
        .select-input,
        .form-input {
          label {
            margin-top: 5px;
          }
        }
      }
    }
  }
}

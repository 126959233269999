@font-face {
	font-family: 'Knockout-30';
	src: url('./Knockout/Knockout-30JuniorWelterwt.eot');
	src: url('./Knockout/Knockout-30JuniorWelterwt.eot?#iefix') format('embedded-opentype'),
		url('./Knockout/Knockout-30JuniorWelterwt.woff2') format('woff2'),
		url('./Knockout/Knockout-30JuniorWelterwt.woff') format('woff'),
		url('./Knockout/Knockout-30JuniorWelterwt.svg#Knockout-30JuniorWelterwt')
			format('svg');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'BlissPro-Regular';
	src: url('./BlissPro/BlissPro-Regular.eot');
	src: url('./BlissPro/BlissPro-Regular.eot?#iefix') format('embedded-opentype'),
		url('./BlissPro/BlissPro-Regular.woff2') format('woff2'),
		url('./BlissPro/BlissPro-Regular.woff') format('woff'),
		url('./BlissPro/BlissPro-Regular.svg#BlissPro-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'BlissPro-Light';
	src: url('./BlissPro/BlissPro-Light.eot');
	src: url('./BlissPro/BlissPro-Light.eot?#iefix') format('embedded-opentype'),
		url('./BlissPro/BlissPro-Light.woff2') format('woff2'),
		url('./BlissPro/BlissPro-Light.woff') format('woff'),
		url('./BlissPro/BlissPro-Light.svg#BlissPro-Light') format('svg');
	font-weight: 300;
	font-style: normal;
}

@font-face {
  font-family: "SourceHanSans-Regular";
  src: url("./Sourcehansans/SourceHanSansCN-Normal.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SourceHanSans-Light";
  src: url("./Sourcehansans/SourceHanSansCN-Normal.ttf") format("truetype");

  font-weight: 300;
  font-style: normal;
}

@font-face {
	font-family: 'FontAwesome';
	src: url('./FontAwesome/fontawesome-webfont.eot');
	src: url('./FontAwesome/fontawesome-webfont.eot?#iefix') format('embedded-opentype'),
		url('./FontAwesome/fontawesome-webfont.woff2') format('woff2'),
		url('./FontAwesome/fontawesome-webfont.woff') format('woff'),
		url('./FontAwesome/fontawesome-webfont.svg#fontawesomeregular') format('svg');
	font-weight: normal;
	font-style: normal;
}
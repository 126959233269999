.global-header {
	padding: 0;
	top: 0;
	width: 100%;

	.accessibility-links {
		position: fixed;
		top: 0;
		left: 0;

		a {
			border: 1px solid black;
		}
	}

	@media only screen and (min-width: 64em) {
		border-bottom: 0;
		background-color: $light-grey;

		&.is-sticky-header {
			padding-top: 80px;
		}
	}

	.nav-onscreen {
		display: block;
		position: fixed;
		top: 100vh;
		background: $brand-white;
		width: 100%;
		z-index: 2;
		transition: top 0.5s;
		height: 100vh;
		padding-top: 10px;

		@media only screen and (min-width: 64em) {
			position: relative;
			top: 0;
			height: auto;
			transform: translateZ(0);
			box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
			padding: 0;

			float: none;
			background: white;
		}

		@media only screen and (min-width: 77.75em) {
			padding-right: 0;
		}

		.logo {
			float: none;
			width: 130px;
			margin: 0 auto;
			padding: 0;

			@media only screen and (min-width: 64em) {
				height: 85px;
				width: 95px;
				float: left;
				margin: 15px 14px;
				display: block;

				img {
					width: 100%;
					height: auto;
				}
			}
		}

		.l-padding {
			padding: 0;
			margin: 0 auto;
			height: 0;

			@media only screen and (min-width: 64em) {
				padding: 0 36px;
				height: auto;
      }
		}

		@keyframes MenuShow {
			from {
				top: -100px;
			}

			to {
				top: 0;
			}
		}

		&.is-sticky {
			position: fixed;
			animation-name: MenuShow;
			animation-duration: 0.4s;
			animation-timing-function: ease-in-out;

			.logo {
				height: auto;
				margin: 10px 10px 0 10px;

				img {
					width: 65px;
					height: 55px;
				}
			}
    }
    
    &.mobile-hidden {
      @include mobile-menu-background-visibility;
    }
	}

	// Most of these styles are set already in `framework.css`
	.accessibility-links {
		position: fixed;
		top: 0;
		left: 0;

		a {
			border: 1px solid black;
    }
    .jump-nav-mobile {
      display: block;
      @media only screen and (min-width: 64em) {
        display: none;
      }
    }

    .jump-nav-desktop {
      display: none;
      @media only screen and (min-width: 64em) {
        display: block;
      }
    }
	}

	.offscreen-background {
		width: 100%;
		height: 100vh;
		position: absolute;
		background-color: rgba(0, 0, 0, 0.7);
		top: 0;
		left: 0;
		z-index: 1;
		visibility: hidden;
		opacity: 0;
		transition: visibility 0s 0.3s, opacity 0.3s 0.3s linear;

		@media only screen and (min-width: 64em) {
			z-index: -1;
		}

		&.is-expanded {
			position: fixed;
			opacity: 1;
			visibility: visible;
		}
	}

	.nav-toggle {
		position: absolute;
		top: 0;
		left: 20px;
	}

	.search-toggle {
		display: none;
		border-radius: 40px;
		width: 40px;
		height: 40px;
		background: $cochlear-yellow sax(Search, $cochlear-purple) no-repeat center center;
		background-size: 23px;
		z-index: 5;

		@media only screen and (min-width: 64em) {
			display: block;
			margin: 37px 0 37px 0;
			position: absolute;
			right: 20px;
		}

		@media only screen and (min-width: 77.75em) {
			right: 36px;
		}

		&.active {
			background: $cochlear-yellow sax(Close, $cool-grey) no-repeat center center;
			background-size: 17px;
		}

		&:hover,
		&:focus {
			background-color: #FFB000;
		}

		&:focus {
			@include mimic-default-browser-focus;
		}
	}
}

#main {
	&.hide-main {
		visibility: hidden;
	}
}

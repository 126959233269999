.global-footer {
	background-color: #51515A;
	color: $brand-white;
	padding: 0;
  font-size: 14px;

	h2 {
		font-size: 22px;
		margin: 0;
	}

	a {
		font-size: 18px;
		color: $brand-white;
		text-decoration: none;
	}

	p {
		font-size: 16px;
	}

	.l-padding {
		padding-top: 60px;
		padding-bottom: 60px;
  }

  &.is-hidden {
    @include mobile-menu-background-visibility;
  }
}

.brandImg {
	text-align: center;
	padding: 20px;

	@media only screen and (min-width: 64em) {
		text-align: initial;
	}

	img {
		display: block;
		width: 120px;
		height: 105px;
		margin: 0 auto;
		text-indent: -999em;
	}
}

.nav-footer {
	@media only screen and (min-width: 64em) {
		padding: 0 20px;
	}

	&.has-5-items .columns2 {
		width: 50%;

		@media only screen and (min-width: 64em) {
			width: 20%;
		}
	}

	.nav-footer-social {
		width: 100%;
		text-align: center;

		h2 {
			display: none;

			@media only screen and (min-width: 64em) {
				display: block;
				text-align: left;
			}
		}

		ul {
		  text-align:left;
			li {
			  	text-align:left;
				height: auto;
			  	width:260px;
			  	margin-bottom: 15px;
			  	img{
					display: inline-block;
					width: 68px;
					height: 68px;
					margin-right: 18px;
					margin-bottom: 10px;
					vertical-align: top;
				  }
			  	.intro{
					display: inline-block;
					vertical-align: top;
					margin-top: 12px;
					p{
					  font-size: 12px;
					  line-height: 12px;
					  margin-top: 0px;
					  text-align:left;
					  color: #FFFFFF;
					  span {
						color: #FEC92E;
					  }
					}
				  }
			}
		}

		a {
			@media only screen and (min-width: 64em) {
				padding: 0;
			}
		}

		.icon {
			background-repeat: no-repeat;
			background-size: 100%;
			height: 30px;
			width: 30px;
			display: block;
			text-indent: -999em;

			&.insta {
				width: 23px;
				margin: 5px 0;
				background-position: 0 5px;

				@media only screen and (min-width: 64em) {
					margin: 0;
					background-position: 0 3px;
				}
			}
		}
	}
}

.nav-footer-item {
	float: left;
	padding-top: 20px;
	padding-left: 20px;

	._2columns {
		width: 50%;
	}

	._1column {
		width: 100%;
	}

	ul {
		display: block;
	}
}

.columns2 {
	@media only screen and (min-width: 64em) {
		width: initial;
	}
}

.column {
	width: 100%;

	@media only screen and (min-width: 64em) {
		width: initial;
	}
}

.subfooter-container {
	margin-bottom: 60px;
	display: flex;
	flex-flow: row wrap;
	background-color: #000;

	@media only screen and (min-width: 64em) {
		margin-bottom: 0;
	}

	a {
		font-size: 14px;
	}
}

.subfooter-link {
	justify-content: space-around;
	padding: 20px;
	margin: 0 auto;

	@media only screen and (min-width: 64em) {
		padding: 30px;
	}
}

.subfooter-copyright {
	flex: auto;
	text-align: center;

	@media only screen and (min-width: 64em) {
		text-align: right;
	}

	p {
		margin: 0;
		justify-content: center;
		color: $brand-white;
		padding: 20px;
		font-size: 14px;

		@media only screen and (min-width: 64em) {
			padding: 30px;
		}
	}
}

.friendly-container{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	background-color: #242424;
	padding: 10px 0;

	.friendly-label{
		font-size: 14px;
		padding: 10px;
		margin: 0 auto;
	}

	.friendly-links{
		display: flex;
		flex-flow: row wrap;
		margin: 0 auto;
		a {
			font-size: 14px;
		}
	}
}

.friendly-link {
	justify-content: space-around;
	padding: 10px 20px;
	margin: 0 auto;

	@media only screen and (min-width: 64em) {
		padding: 10px 30px;
	}
}

.contact-us-dropdown-cn {
	margin: 0;
	padding: 0;
  	.contacCRegion {
	  background-color: #fff;
	  padding-top: 80px;
	  padding-bottom: 55px;
	  @media only screen and (min-width: 64em) {
		.contact-us-container{
		  padding-left: 222px;
		}
	  }
	  h2 {
		display: inline-block;
		font-size: 32px;
		line-height: 46px;
		letter-spacing: 1px;
		margin:0 0 20px 0;
		padding: 0;
		@media only screen and (min-width: 64em) {
		  margin:0 13% 20px 0;
		}
	  }
	  .el-select {
		display: inline-block;
		.el-input {
		  position: relative;
		  font-size: 14px;
		  width: 100%;
		  display: block;
		  .el-input__inner {
			display: block;
			width: 381px;
			height: 38px;
			background: #fff;
			box-shadow: 0px 0px 4px 0px #C4C4C4;
			border-radius: 22px;
			border: 1px solid #C4C4C4;
			margin-top: 4px;
			padding: 8px 23px;
			font-size: 16px;
			color: #5f5f5f;
			line-height: 22px;
		  }
		  .el-input__suffix {
			right: 12px !important;
			transition: all .3s;
			position: absolute;
			top: 0;
			-webkit-transition: all .3s;
			height: 100%;
			color: #C0C4CC;
			text-align: center;
			.el-input__suffix-inner {
			  pointer-events: all;
			  [class*=" el-icon-"]{
				font-family: element-icons!important;
				speak: none;
				font-style: normal;
				font-weight: 400;
				font-variant: normal;
				text-transform: none;
				line-height: 1;
				vertical-align: baseline;
				display: inline-block;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
			  }
			  .el-input__icon {
				height: 100%;
				width: 25px;
				text-align: center;
				-webkit-transition: all .3s;
				transition: all .3s;
				line-height: 40px;
			  }
			  .el-select__caret {
				color: #FEC92E !important;
				font-weight: 500;
				font-size: 14px;
				cursor: pointer;
				transition: transform .3s,-webkit-transform .3s;
			  }
			  .el-icon-arrow-up{
				transform: rotate(180deg);
				background-image: sax(Chevron, $cochlear-yellow);
				background-repeat-y: no-repeat;
				background-position: center;
			  }
			  .el-icon-arrow-down{
				background-image: sax(Chevron, $cochlear-yellow);
				transform: rotate(0deg);
				background-repeat-y: no-repeat;
				background-position: center;
			  }
			}
		  }
		}
		.el-option{
		  display: none;
		  position: absolute;
		  list-style: none;
		  padding: 6px;
		  border: 1px solid #E4E7ED;
		  border-radius: 4px;
		  background-color: #FFF;
		  -webkit-box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
		  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
		  -webkit-box-sizing: border-box;
		  box-sizing: border-box;
		  margin: 10px 0;
		  z-index: 2;
		  &.on{
			display: block;
		  }
		  .el-option-scroll{
			height: 274px;
			width: 368px;
			overflow: scroll;
			overflow-x: hidden;
			&::-webkit-scrollbar
			{
			  width: 6px;
			  height: 6px;
			}
			&::-webkit-scrollbar-thumb
			{
			  border-radius: 10px;
			  -webkit-box-shadow: inset 0 0 6px rgba(152,140,140,.3);
			  background-color: #d2d2d2;
			  transition: all .3s;
			  display:none;
			}
			&:hover{
			  transition: all .3s;
			  &::-webkit-scrollbar-thumb
			  {
				display:block;
			  }
			}
		  }
		  ul{
			list-style: none;
			padding: 6px 0;
			margin: 0;
			-webkit-box-sizing: border-box;
			box-sizing: border-box;
			li{
			  font-size: 14px;
			  padding: 0 20px;
			  position: relative;
			  white-space: nowrap;
			  overflow: hidden;
			  text-overflow: ellipsis;
			  color: #606266;
			  height: 34px;
			  line-height: 34px;
			  -webkit-box-sizing: border-box;
			  box-sizing: border-box;
			  cursor: pointer;
			  margin-top: 0;
			  .firstLetter{
				float: right;
			  }
			  &.selected {
			  	color: #5A5E66!important;
			  	font-weight: 700;
				}
			  &:hover {
				background-color: #FEC92E;
			  }
			}
		  }
		  &::before {
			content: "";
			position: absolute;
			top: -.41em;
			right: 2em;
			padding: .35em;
			background: inherit;
			border: inherit;
			border-right: 0;
			border-bottom: 0;
			transform: rotate(45deg);
		  }
		}
	  }

	}
    .contacDetail {
	  background-color: #f6f6f6;
	  padding-top: 70px;
	  padding-bottom: 60px;
	  min-height: 250px;
	  @media only screen and (min-width: 64em) {
		.contact-us-container{
		  padding-left: 222px;
		}
	  }
	}
}

.tabbed-content {
	.tab-items {
		padding: 0;
		margin: 0;
		display: block;

		@media only screen and (min-width: 64em) {
			display: flex;
		}

		.tab {
			height: auto;
			width: 100%;
			background-color: $light-grey;
			cursor: pointer;
			padding: 20px;
			text-align: left;
			font-family: $font-header;
			margin: 0;
			position: relative;
			font-weight: 700;
			color: $cochlear-purple;
			line-height: 20px;
			border: 1px solid rgba(204, 204, 204, 0.5);
			outline: 0;

			&.active {
				border: 0;
				border-top: 5px solid $cochlear-yellow;
				background-color: $brand-white;
			}

			&:first-child {
				&.active {
					border-left: 1px solid rgba(204, 204, 204, 0.5);
				}
			}

			&:last-child {
				&.active {
					border-right: 1px solid rgba(204, 204, 204, 0.5);
				}
			}
		}
	}

	.tab-panel {
		text-decoration: none;
		overflow-x: scroll;

		@media only screen and (min-width: 64em) {
			padding: 20px;
			border: 1px solid rgba(204, 204, 204, 0.5);
			border-top: 0;
			overflow: auto;
		}

		img {
			max-width: 100%;
			height: auto;
			display: block;
			margin: 0 auto;
		}

		table {
			margin: 0;
		}

		table,
		th,
		td {
			border: 1px solid rgba(204, 204, 204, 0.7);
		}

		tbody {
			tr {
				&:nth-child(2n) {
					th,
					td {
						background-color: white;
					}
				}

				th {
					font-size: 16px;

					&:nth-child(2n) {
						background-color: $cochlear-yellow;
						color: $cool-grey;
					}

					&:nth-child(3n) {
						background-color: #25699D;
						color: white;
					}

					&:nth-child(4n) {
						background-color: #C7022B;
						color: white;
					}
				}

				th,
				td {
					padding: 11px;
				}
			}
		}
	}

	.hide-button {
		width: 100%;
		background-color: $lighter-grey;
		color: $cochlear-purple;
		border: 0;
		padding: 15px 40px;
		font-weight: 600;
	}

	.active {
		background-color: $brand-white;
		color: $cochlear-purple;
	}

	&.can-collapse {
		.tab {

			background-color: $brand-white;
			border-left: 0;
			border-right: 0;
			border-top: 0;
			padding: 10px;
			font-size: 16px;

			&:first-child {
				&.active {
					border-left: 0;
				}
			}

			&:last-child {
				&.active {
					border-right: 0;
				}
			}

			&:before {
				content: '';
				position: absolute;
				transition: height 0.3s;
				background-color: $cochlear-yellow;
				width: 100%;
				top: 0;
				left: 0;
				height: 0;
			}

			&:after {
				content: ' ';
				right: 5px;
				top: 50%;
				width: 20px;
				height: 20px;
				margin-top: -10px;
				position: absolute;
				background-image: sax(Chevron, $cochlear-purple);
				background-size: 100%;
				transform: rotate(0deg);
				@include transition(0.2s);

				@media only screen and (min-width: 64em) {
					right: 20px;
				}
			}

			&.active {
				border-top: 0;

				&:before {
					height: 5px;
				}

				&:after {
					background-image: sax(Chevron, $cochlear-purple);
					transform: rotate(180deg);
				}
			}

			&:hover,
			&:focus {

				background-color: $brand-white;

				&:before {
					height: 5px;
				}
			}
		}

		.tab-items {
			display: flex;
		}

		.tab-item-description {
			animation-name: slideDown;
			animation-iteration-count: 1;
			animation-timing-function: ease-in-out;
			animation-duration: 2s;
		}

		.tab-panel {
			overflow: initial;
			background-color: $brand-white;
			border: 0;
			padding: 20px;

			table {
				border: 0;
			}

			td {
				border: 0;
				width: 100%;
				display: block;
				background-color: $brand-white;
				padding: 0;

				@media only screen and (min-width: 48em) {
					display: table-cell;
					width: 33%;
					padding: 0 20px 30px 0;
				}
			}

			tbody {
				tr {
					&:nth-child(n) {
						th,
						td {
							background-color: $brand-white;
						}
					}

					&:nth-child(2n) {
						th,
						td {
							background-color: $brand-white;
						}
					}
				}
			}
		}
	}
}

.Product,
.product-page {
	.tabbed-content {
		background-color: $light-grey;
		padding: 30px 0;

		@media only screen and (min-width: 64em) {
			.tab {
				padding: 15px 40px;
			}

			.tab-panel {
				padding: 40px;
			}
		}
	}
}

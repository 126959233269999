.breadcrumbs {
	display: none;
	font-size: 16px;
	margin: 20px auto 60px auto;

	@media only screen and (min-width: 64em) {
		display: block;
	}

	ul {
		li {
			.is-active {
				font-size: 16px;
			}

			&::before {
				content: '/';
				left: 5px;
				top: 0;
				margin-top: 0;
			}
		}
	}

	a {
		color: $link-colour;
		font-size: 16px;
	  	&.no-link{
		  color: #56565a;
		  cursor: default;
		  &:hover,
		  &:focus {
			text-decoration:none;
			color: #56565a;
		  }
		}
		&:hover,
		&:focus {
			color: $link-colour;
		}
	}
}

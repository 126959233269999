//This one is always the full width hero card, so just pull out the "is-large" css from the repo
.hero-card {
	border: 0;
	overflow: hidden;
	margin: 0 -20px 0 -20px;
	padding: 0 20px 20px 20px;
	cursor: auto;

	&::before {
		content: ' ';
		background-color: $cochlear-yellow;
		height: calc(100% - 70px);
		width: 100%;

		position: absolute;
		bottom: -70px;
		z-index: -1;
		opacity: 1;
		border-radius: 0 0 50px 0;
		display: none;

		@media screen and (min-width: 64em) {
			display: block;
		}
	}

	@media screen and (min-width: 64em) {
		margin: 0 auto;
		max-width: none;
		padding: 0 0 90px 0;
		z-index: 2;
		overflow: initial;
	}

	.image {
		height: 350px;
		position: relative;
		left: 30px;
		display: block;

		img {
			max-width: none;
			height: 100%;
			display: block;
			width: auto;
			border-radius: 0 0 0 50px;
			transform: none;
		}
	}

	.cta {
		@media screen and (min-width: 64em) {
			margin: 0;
		}
	}

	a {
		text-decoration: none;
	}

	.content-card__grouper {
		@media screen and (min-width: 64em) {
			display: block;
			max-width: 980px;
			margin: 0 auto;
			position: relative;
		}

		@media only screen and (min-width: 77.75em) {
			max-width: 1100px;
		}
	}

	.content-card__grouper {
		.cta-button {
			@include cta-button-hero-card($brand-white, $cochlear-purple);

			&.secondary {
				background-color: transparent;
				border: 1px solid $link-colour;
			}
		}

		&:hover {
			@include cta-button-hero-card-hover($cochlear-purple, $brand-white);
		}
	}

	.content-container {
		background-color: $cochlear-yellow;
		border-radius: 0 0 50px 0;
		float: none;
		width: 100%;
		height: 340px;
		display: table;

		@media screen and (min-width: 64em) {
			padding: 20px 0 30px 0;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			padding: 50px 20px 0;
			margin-bottom: 25px;
			font-size: 38px;
			line-height: 1;

			@media screen and (min-width: 64em) {
				padding: 0;
			}
		}

		p {
			padding: 0 20px;
			font-size: 18px;
			margin-bottom: 40px;

			@media screen and (min-width: 64em) {
				line-height: 25px;
				padding: 0;
			}
		}

		.content-container__grouper {
			display: flex;
			justify-content: center;
			flex-direction: column;

			@media screen and (min-width: 64em) {
				float: right;
				margin-right: 0;
				width: 450px;
				height: 340px;
			}
		}
	}

	.img-container {
		height: 280px;
		bottom: -30px;
		left: -25px;

		@media screen and (min-width: 48em) {
			height: 480px;
		}

		@media screen and (min-width: 64em) {
			margin-top: 0;
			position: absolute;
			left: 0;
			top: 40px;
			bottom: auto;
			width: 505px;
			height: 410px;
		}

		@media only screen and (min-width: 77.75em) {
			width: 550px;
		}

		a {
			width: 100%;
			padding-right: 0;
			left: 0;
			padding-left: 0;

			@media screen and (min-width: 64em) {
				padding-left: 50px;
			}

			img {
				width: auto;
				border-radius: 0;
				height: 100%;

				@media screen and (min-width: 64em) {
					width: 100%;
					height: auto;
				}
			}
		}

		img,
		.img {
			bottom: 0;
			z-index: 2;
			height: 100%;
			width: auto;
			transform: translateY(0);
			position: relative;
			margin: 0;
			top: 0;

			@media screen and (min-width: 64em) {
				right: 0;
				left: 50%;
				transform: translate(-50%, 0);
			}
		}
	}

	&:hover {
		transform: perspective(1000px) translate3d(0, 0, 0);
		box-shadow: none;

		&::before {
			box-shadow: none;
		}
	}

	&.no-wrap-link {
		cursor: auto;

		&:hover,
		&:focus {
			.content {
				h1,
				h2,
				h3,
				h4,
				h5,
				h6,
				p {
					color: $cool-grey;
				}
			}
		}
	}

	&.is-secondary {

		margin-bottom: 50px;

		&::before {
			background-color: $brand-white;
		}

		&:hover,
		&:focus {
			h1,
			h2,
			h3,
			h4,
			h5,
			h6,
			p {
				color: $cool-grey;
			}
		}

		.img-container {
			@media screen and (min-width: 64em) {
				left: auto;
				right: 0;
			}
		}

		.content-card__grouper {
			.content-container {
				background-color: $brand-white;

				.content-container__grouper {
					@media screen and (min-width: 64em) {
						float: left;
					}
				}

				.cta-button {
					background-color: $cochlear-purple;
					color: $brand-white;

					&:after {
						background-image: sax(Chevron, white);
					}
				}
			}

			&:hover {
				@include cta-button-hero-card-hover($cochlear-purple, $link-colour-hover);
			}
		}
	}
}

.article .hero-card {
	background-color: $brand-white;
}

/* ALTERNATE COLOURS - ONLY THE FIRST IS GREY */
.landing-page {
	.article > .hero-card {
		background-color: $light-grey;
	}

	.article > .hero-card ~ .hero-card {
		background-color: $brand-white;
	}
}

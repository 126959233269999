.page-mobile-header {
	display: flex;
	border-bottom: 1px solid $mid-grey;
	position: fixed;
	height: 75px;
	width: 100%;
	align-items: center;
	justify-content: center;
	top: 0px;
	background-color: #fff;
	z-index: 1;

	+ * {
		margin-top: 75px;
	}

	@media only screen and (min-width: 64em) {
		display: none;
		+ * {
			margin-top: 0px;
		}
	}

	.mobile-logo {
		float: none;
		width: 60px;
    height: auto;
		display: block;

		img {
			width: 100%;
			height: auto;
			display: block;
		}
	}

	#mobile-menu {
		position: absolute;
		right: 15px;
    width: 38px;
    height: 30px;
  }

  #mobile-menu span {
    display: block;
    position: absolute;
    height: 6px;
    width: 50%;
    background: #666;
    opacity: 1;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .3s ease-in-out;
    -moz-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
  }
  
  #mobile-menu span:nth-child(even) {
    left: 50%;
    border-radius: 0 9px 9px 0;
  }
  
  #mobile-menu span:nth-child(odd) {
    left:0px;
    border-radius: 9px 0 0 9px;
  }
  
  #mobile-menu span:nth-child(1), #mobile-menu span:nth-child(2) {
    top: 0px;
  }
  
  #mobile-menu span:nth-child(3), #mobile-menu span:nth-child(4) {
    top: 12px;
  }
  
  #mobile-menu span:nth-child(5), #mobile-menu span:nth-child(6) {
    top: 24px;
  }
  
  #mobile-menu.open span:nth-child(1),#mobile-menu.open span:nth-child(6) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  
  #mobile-menu.open span:nth-child(2),#mobile-menu.open span:nth-child(5) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  
  #mobile-menu.open span:nth-child(1) {
    left: 3px;
    top: 6px;
  }
  
  #mobile-menu.open span:nth-child(2) {
    left: 16px;
    top: 6px;
  }
  
  #mobile-menu.open span:nth-child(3) {
    left: -50%;
    opacity: 0;
  }
  
  #mobile-menu.open span:nth-child(4) {
    left: 100%;
    opacity: 0;
  }
  
  #mobile-menu.open span:nth-child(5) {
    left: 3px;
    top: 19px;
  }
  
  #mobile-menu.open span:nth-child(6) {
    left:16px;
    top: 19px;
  }
}
//rtl-styles
div#root > div[dir='rtl'] {
  .page-mobile-header{
    #mobile-menu {
      left: 15px;
      right: auto;
     
    }
  }
}
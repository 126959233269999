.direct-download {
	margin: 0 0 35px 0;
	padding: 30px 40px;
	background-color: $light-grey;
	border-left: solid $cochlear-yellow 5px;

	h3 {
		border-bottom: 0;
		padding-top: 0;
		margin-bottom: 30px;
	}

	.cta-button {
		@include cta-button;
	}

	.download-box {
		background: $brand-white;
		padding: 25px 0 25px 73px;
		position: relative;
		margin-bottom: 30px;

		p {
			margin: 0;
		}

		.title {
			font-weight: bold;
		}

		&:before {
			content: '';
			background-image: sax(PDF, $cochlear-yellow);
			width: 25px;
			height: 29px;
			position: absolute;
			top: calc(50% - 21.5px);
			left: 27px;
			background-size: 100% 100%;
		}
	}
}

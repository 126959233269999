.volunteer-header {
	width: 100%;
	padding-top: 50px;
	margin: 0 auto 40px auto;
	position: relative;
	z-index: 1;
	text-align: center;

	&:before {
		content: ' ';
		position: absolute;
		top: 0;
		left: 0;
		height: 130px;
		width: 100%;
		background-color: $cochlear-yellow;
	}

	.image-container {
		display: block;
		width: 160px;
		height: 160px;
		margin: 0 auto;
		border-radius: 130px;
		overflow: hidden;
		position: relative;

		img {
			width: 300px;
			height: auto;
			margin-left: -50%;
		}
	}

	h1 {
		margin: 20px 0 5px 0;
	}

	p {
		margin: 5px 0 25px 0;
	}
}

.volunteer-page {
	.cm-rich-text {
		.cta-button {
			margin-top: 20px;

			@include cta-button;
		}

		.cta-button + .cta-button {
			@extend .secondary;

			@media screen and (min-width: 64em) {
				margin-left: 20px;
			}
		}

		h2,
		h3 {
			font-size: 22px;
			line-height: 28px;
		}
	}
}

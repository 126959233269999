.location-list {
	padding-top: 20px;

	.location-list-title {
		border-bottom: 5px solid $cochlear-yellow;
		padding-bottom: 20px;
	}

	.location-list-item {
		padding-bottom: 40px;

		p {
			font-size: 16px;
			line-height: 24px;
		}

		.location-list-item-title {
			font-family: $font-primary-bold;
			font-size: 18px;
			line-height: 28px;
			font-weight: 700;
		}
	}

	a {
		color: $link-colour;
	}

	@media screen and (min-width: 64em) {
		.location-list-flex {
			display: flex;
			flex-flow: row wrap;
			align-content: flex-start;
			width: 100%;

			h3 {
				width: 100%;
			}

			.location-list-item {
				width: 33.33%;
				flex: 0 1 auto;
				padding: 0 15px 40px 0;
			}
		}
	}
}

.sl.l-padding.is-full-width .swiper-button-prev {
	left: 0;
}

.sl.l-padding.is-full-width .swiper-button-next {
	right: 0;
} 

.content-card-listing-wrapper {
	.listing-title {
		margin-bottom: 25px;
	}

	.swiper-wrapper {
		padding: 20px 0;

		.swiper-slide {
			//text-align: center;
			font-size: 18px;
			background: #fff;
			/* Center slide text vertically */
			display: -webkit-box;
			display: -ms-flexbox;
			display: -webkit-flex;
			display: flex;
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			-webkit-justify-content: center;
			justify-content: center;
			-webkit-box-align: center;
			-ms-flex-align: center;
			-webkit-align-items: center;
			align-items: center;
			height: auto;

			.content-card {
				.content-container {
					.cta-grouping {
						.cta-button {
							z-index: 1;
							position: absolute;
							bottom: 0;
							right: 0;
							left: 0;
							width: auto;
						}
					}
				}
			}
		}
	}
}

.search-results {
	.search-results-box {
		background-color: $cochlear-black;
		padding: 60px 0 30px 0;

		@media only screen and (min-width: 64em) {
			border-radius: 0 0 40px 0;
			padding: 110px 0 45px 0;
			margin-bottom: 50px;
		}

		// Search box
		.search-box {
			position: relative;
			opacity: 1;
			display: flex;
			background-color: transparent;
			z-index: 0;

			.search-input-wrapper {
				border-bottom: 1px solid $brand-white;
				display: flex;
				position: relative;

				@media only screen and (min-width: 64em) {
					width: 100%;
				}

				.underline {
					font-size: 30px;
					height: 3px;
					overflow: hidden;
					position: absolute;
					bottom: -1px;
					left: 0;
					border-bottom: 3px solid $cochlear-yellow;

					@media only screen and (min-width: 64em) {
						font-size: 50px;
					}
				}
			}

			.search-input {
				font-family: $font-primary;
				font-size: 30px;
				background-color: transparent;
				border: 0;
				color: $brand-white;
				outline: 0;

				@media only screen and (min-width: 64em) {
					font-size: 50px;
					width: 100%;
				}
			}

			.search-toggle,
			.search-clear {
				background-color: transparent;
				background-repeat: no-repeat;
				background-size: 100%;
				border: 0;
				padding: 0;
			}

			.search-toggle {
				background-image: sax(Search, $brand-white);
				background-position: 0 10px;
				width: 30px;
				height: 45px;
				margin-right: 8px;

				@media only screen and (min-width: 64em) {
					margin-right: 20px;
					width: 40px;
				}
			}

			.search-clear {
				background-image: sax(Close, $brand-white);
				background-position: center center;
				width: 20px;

				@media only screen and (min-width: 64em) {
					width: 30px;
				}
			}
		}
	}

	.no-results {
		margin-bottom: 70px;
	}

	// Search items
	.search-items {
		list-style: none;
		padding: 0;

		.search-item {
			padding: 10px 30px;
			margin: 0 -20px;
			border-bottom: 1px solid $light-grey;

			a {
				text-decoration: none;
				display: block;
				padding: 10px;

				h3,
				p {
					font-size: 18px;
					line-height: 24px;
				}

				h3 {
					color: $link-colour;
					margin-bottom: 5px;
				}

				&:hover,
				&:focus {
					background-color: $light-grey;

					h3 {
						text-decoration: underline;
					}
				}
			}
		}
	}

	// Search pagination
	.search-pagination {
		display: flex;
		justify-content: center;
		margin: 40px 0;

		p {
			margin: 0 30px;
			line-height: 40px;
		}

		.search-next,
		.search-prev {
			border: 0;
			padding: 0;
			position: relative;
			color: $cochlear-purple;
			line-height: 40px;
			background-color: transparent;
			text-indent: -999em;

			@media only screen and (min-width: 64em) {
				text-indent: initial;
			}
		}

		.search-next {
			padding-right: 30px;

			&:after {
				@include chevron(-90deg, $cochlear-purple);
				right: 0;
				top: 2px;
			}
		}

		.search-prev {
			padding-left: 30px;

			&:before {
				@include chevron(90deg, $cochlear-purple);
				left: 0;
				top: 2px;
			}
		}
	}
}

.product-testimonial {
	background-color: $light-grey;
	padding: 30px 0;

	@media only screen and (min-width: 64em) {
		padding: 60px 0;
	}

	table {

		margin: 0;

		tr {
			background-color: transparent;

			&:nth-child(2n) {
				td {
					background-color: transparent;
					border: 0;
				}
			}
		}

		td {
			border: 0;
			width: 100%;
			display: block;
			padding: 10px 0;
			background-color: transparent;
			vertical-align: top;

			h4 {
				@extend .h3;
				font-family: $font-primary;
				margin-bottom: 0;
			}

			p {
				margin-top: 0;
			}

			&:nth-child(2n) {
				background-color: transparent;
			}

			@media only screen and (min-width: 64em) {
				display: table-cell;
				width: 50%;
				padding: 0 70px 30px 0;

				h4 {
					margin-bottom: 20px;
				}
			}
		}
	}

	.product-testimonial-container {
		display: flex;
		flex-direction: column;

		.testimonial-container {
			display: flex;
			margin-top: 25px;
			min-height: 300px;
			flex-direction: column-reverse;

			@media only screen and (min-width: 64em) {
				flex-direction: row;
			}

			.video-play-icon {
				@include video-play-icon();
				transform: perspective(1000px) translate3d(0, 0, 0);
				transition: transform 0.2s, box-shadow 0.2s;
				cursor: pointer;

				&:hover,
				&:focus {
					transform: perspective(1000px) translate3d(0, 0, 21px);
					box-shadow: 0 3px 30px rgba(0, 0, 0, 0.3);
				}

				&:focus {
					@include mimic-default-browser-focus;
				}

				&:active {
					transform: perspective(1000px) translate3d(0, 0, 15px);
				}
			}

			.image-container {
				height: 300px;
				border: 0;
				padding: 0;
				display: flex;
				align-items: center;
				overflow: hidden;

				img {
					width: auto;
					height: 300px;
					position: relative;
					top: 0;
					left: 50%;
					transform: translate(-50%);
				}

				@media only screen and (min-width: 48em) {
					img {
						width: 100%;
						height: auto;
					}
				}

				@media only screen and (min-width: 64em) {
					z-index: 2;
					height: 400px;

					img {
						width: auto;
						height: 400px;
					}
				}
			}

			.blockquote-container {
				padding: 40px 30px 60px 30px;
				align-self: center;
				margin: 0 40px -20px 0;
				z-index: 1;
				border-radius: 0 0 40px 0;
				position: relative;
				background-color: $brand-white;

				@media only screen and (min-width: 48em) {
					padding-bottom: 40px;
					padding-top: 40px;
				}

				@media only screen and (min-width: 64em) {
					z-index: 2;
					padding: 60px 80px 60px;

					&::before {
						content: '';
						width: 21px;
						height: 100%;
						background-color: $brand-white;
						position: absolute;
						top: 0;
						left: -20px;
					}
				}

				blockquote {
					margin: 0;
				}
			}

			.video-trigger,
			.blockquote-container {
				width: 100%;

				@media only screen and (min-width: 64em) {
					width: 50%;
				}
			}
		}

		.content-container {
			margin-top: 20px;

			.scWebEditInput.scEnabledChrome[contentEditable="true"] {
				width: 100%;
			}

			@media only screen and (min-width: 64em) {
				margin-top: 60px;
			}

			a {
				color: $link-colour;
			}

			.container-group {
				display: flex;
				flex-wrap: wrap;
				list-style: none;
				margin: 0;
				padding: 0;

				.container-item {
					width: 100%;
					margin: 0 0 40px;

					@media only screen and (min-width: 64em) {
						width: 50%;
						padding-right: 70px;
					}

					.h3 {
						display: block;

						* {
							font-size: 28px;
							font-weight: 700;

							sup {
								font-size: 20px;
							}
						}
					}

					ul {
						padding-left: 20px;
						margin: 0;

						li {
							list-style: disc;
						}
					}
				}
			}
		}
	}

	&.is-secondary {
		background-color: $brand-white;

		.product-testimonial-container {
			.testimonial-container {
				@media only screen and (min-width: 64em) {
					flex-direction: row-reverse;
				}

				.video-trigger {
					@media only screen and (min-width: 64em) {
						z-index: 1;
					}
				}

				.blockquote-container {
					background-color: $light-grey;

					@media only screen and (min-width: 64em) {
					border-bottom-right-radius: 0;
					border-bottom-left-radius: 40px;
						margin: 0;

						&::before {
							background-color: $light-grey;
							left: auto;
							right: -20px;
						}
					}
				}
			}
		}
	}
}

.show {
  display: block;

  &.horizontal-rule {
    border: 0;
    background-color: transparent;
    margin: 10px 0;
    height: 1px;
  }
}

.hide {
  display: none;
}

.contact-form {
  .l-content-column {
    margin-top: 60px;
  }
}

.form-box {
  background-color: $light-grey;
  margin: 0 -20px;
  padding: 30px 20px;
  overflow: hidden;

  .bottom-border-line {
    border-bottom: 5px solid #fdc82f;

    &.bottom-padding {
      padding-bottom: 20px;
    }
  }

  .horizontal-rule {
    border: 0;
    background-color: transparent;
    margin: 10px 0;
    height: 1px;
  }

  h3 {
    border-bottom: 5px solid $cochlear-yellow;
    padding-bottom: 18px;
    padding-top: 25px;
  }

  label,
  input,
  textarea {
    display: block;
    width: 100%;
  }

  input,
  textarea {
    &:focus {
      outline: none;
      border-bottom: 3px solid $cochlear-yellow;
    }
  }

  label {
    font-size: 16px;
    margin-top: 30px;
    color: $cool-grey;
    font-weight: bold;
  }

  textarea {
    /* margin-top: 30px; */
    resize: none;
    height: 200px;
    border: 1px solid #ccc;
    font-family: $font-primary !important;
    padding: 10px;
  }

  input {
    border: 1px solid #ccc;
    padding: 10px;
    font-family: $font-primary;
  }

  fieldset.radio-group {
    font-size: 16px;
    margin-top: 30px;
    color: $cool-grey;
    font-weight: bold;

    legend {
      font-family: inherit;
      font-weight: inherit;
      line-height: inherit;
      font-size: inherit;
      color: inherit;
      float: none;
      margin-bottom: inherit;
    }
  }

  .radio-container {
    margin: 10px 0;

    .radiopoint {
      &::after {
        top: 7px;
        left: 7px;
        width: 7px;
        height: 7px;
      }
    }
  }

  span {
    color: $cool-grey;
    font-size: 16px;
  }

  .dropdown {
    // stylelint-disable property-no-vendor-prefix
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    background-color: white;
    width: 100%;
    border-radius: 0;
    padding: 10px;
    // background-image: url('/images/Icon_Purple_Chevron.svg');
    background-image: sax(Chevron, $cochlear-purple);
    background-repeat: no-repeat;
    background-position: calc(100% - 5px) 50%;
    font-family: $font-primary;
    border: solid 1px #ccc;

    &::-ms-expand {
      display: none;
    }
  }

  .details-box {
    h4 {
      padding-top: 20px;
    }

    span,
    button {
      display: block;
      padding-left: 40px;
      position: relative;
      font-family: $font-primary;
      font-weight: normal;

      &::before {
        content: '';
        width: 20px;
        height: 20px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        background-size: 100%;
      }

      &.phone {
        color: $cool-grey;

        &::before {
          background-image: url('/images/Icon_Yellow_Phone.svg');
        }
      }

      &.link {
        &::before {
          background-image: url('/images/Icon_Yellow_Mail.svg');
        }
      }

      &.cta {
        position: relative;
        display: block;
        border: 0;
        border-top: 1px solid $light-grey;
        border-left: 5px solid $cochlear-yellow;
        background-color: $link-colour;
        color: #ffffff;
        font-size: 18px;
        font-weight: bold;
        text-align: left;
        line-height: 30px;
        padding: 10px 55px 10px 30px;
        float: left;
        border-radius: 30px;
        cursor: pointer;
        width: 100%;
        border: 0;
        margin: 0;
        margin-top: 20px;
        text-align: center;

        &:hover {
          transition: all 0.2s ease-in-out;
          background-color: #672ac5;
          color: white;
        }

        &::after {
          content: ' ';
          right: 25px;
          top: 16px;
          width: 20px;
          height: 20px;
          position: absolute;
          background-image: sax(Chevron);
          background-size: 100%;
          // -webkit-transform: rotate(-90deg);
          // -moz-transform: rotate(-90deg);
          transform: rotate(-90deg);
        }

        &:active {
          // background-color: #ffffff;
          color: $link-colour;
          background-color: transparent;
          border: 1px solid $link-colour;

          &::after {
            background-image: sax(Chevron);
          }
        }

        &.is-light {
          background-color: transparent;
          color: $link-colour;
          border: 1px solid $link-colour;

          &::after {
            background-image: url('/images/Icon_Purple_Chevron.svg');
          }

          &:active {
            background-color: $link-colour;
            color: #ffffff;

            &::after {
              background-image: url('/images/Icon_White_Chevron.svg');
            }
          }
        }

        &.cancel {
          &::after {
            background-image: url('/images/Icon_White_Close.svg');
            height: 13px;
            width: 13px;
            margin-top: 2px;
          }

          &:active {
            &::after {
              background-image: url('/images/Icon_Purple_Close.svg');
            }
          }

          &.is-light {
            &::after {
              background-image: url('/images/Icon_Purple_Close.svg');
            }

            &:active {
              &::after {
                background-image: url('/images/Icon_White_Close.svg');
              }
            }
          }
        }
      }
    }
  }

  a {
    color: $link-colour;
    cursor: pointer;
  }

  .show {
    display: block;
  }

  .hide {
    display: none;
  }

  .error-message-box {
    border: 1px solid $mid-grey;
    background-color: white;
    padding: 0;
    margin-top: 20px;
    border-radius: 6px;

    span {
      border-bottom: 1px solid $mid-grey;
      width: 100%;
      display: inline-block;
      padding: 20px;
      color: #eb0606;
    }

    & > span {
      position: relative;
      padding-left: 50px;

      &::before {
        content: '';
        width: 20px;
        height: 20px;
        background-size: 100%;
        background-image: url('/images/error.svg');
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
      }
    }

    ul {
      // list-style: none;
      margin-top: 0;
      // padding: 20px 0;
      padding-left: 40px;

      li {
        // display: block;
        color: #eb0606;
        margin: 12px 0;
        padding: 0 10px;

        span {
          border: 0;
          padding: 0;
        }
      }
    }
  }

  .error-summary {
    margin-top: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: #fff;
    // position: relative;
    .items {
      margin-top: 14px;
      padding-left: 35px;
      position: relative;
    }

    span[role="link"] {
      padding: 0;
      color: $link-colour;
      cursor: pointer;
      text-decoration: underline;
    }
    
    .multiple-errors-box {
      border-bottom: 1px solid #ccc;
      padding: 20px;

      &::before {
        content: '';
        width: 20px;
        height: 20px;
        background-size: 100%;
        background-image: url('/images/error.svg');
        //position: absolute;
        top: 35px;
        left: 20px;
        //transform: translateY(-50%);
      }

      p {
        color: #ff0037;
        letter-spacing: normal;
        padding-left: 40px;
        font-weight: bold;
        font-size: 16px;
      }
    }

    .invalid-item {
      color: #ff0037;
      list-style: none;
      letter-spacing: normal;
      margin: 20px 0;
      font-weight: bold;
      font-size: 16px;

      &::before {
        content: '';
        position: absolute;
        left: 20px;
        font-size: 27px;
        height: 5px;
        width: 5px;
        background-color: red;
        border-radius: 50%;
        margin-top: 12px;
      }
    }
  }
}

.underlined {
  border-bottom: 5px solid #fdc82f;
  padding-bottom: 18px;
}

// layout
.l-content-column {
  @media screen and (min-width: 64em) {
    .form-box-failure {
      margin: 0;
      padding: 30px 40px;
      background-color: $light-grey;
      // margin: 0 -20px;
      // padding: 30px 20px;
      overflow: hidden;

      .a {
        display: inline-block;
      }
    }
  }
  
  @media screen and (min-width: 64em) {
    .form-box {
      margin: 0;
      padding: 30px 40px;

      .dropdown {
        // padding: 10px;
      }

      .sl-list.has-2-items {
        .sl-item {
          &.date {
            flex: 3 1;

            .warn {
              margin-top: 20px;

              &label {
                margin-top: 10px;
              }
            }

            .multi-container {
              flex: auto;
            }
          }

          &:first-child {
            padding-right: 10px;
          }

          &:last-child {
            padding-left: 10px;
          }
        }
      }

      .details-box {
        span,
        button {
          &.cta {
            width: auto;
            margin-right: 20px;
            border: 1px solid #3f1482;

            &:hover {
              // background-color: #ffffff;
              //color: $link-colour;
              //background-color: transparent;
              border: 1px solid $link-colour;
              transition: all 0.2s ease-in-out;
              background-color: #672ac5;
              color: white;

              &::after {
                background-image: sax(Chevron);
                right: 20px;
                transition: all 0.2s ease-in-out;
              }
            }

            &.is-light {
              background-color: transparent;
              color: $link-colour;
              border: 1px solid $link-colour;

              &::after {
                background-image: url('/images/Icon_Purple_Chevron.svg');
              }

              &:hover {
                background-color: $link-colour;
                color: #ffffff;

                &::after {
                  background-image: url('/images/Icon_White_Chevron.svg');
                }
              }
            }

            &.cancel {
              &::after {
                background-image: url('/images/Icon_White_Close.svg');
                height: 13px;
                width: 13px;
                margin-top: 2px;
              }

              &:hover {
                &::after {
                  background-image: url('/images/Icon_Purple_Close.svg');
                }
              }

              &.is-light {
                &::after {
                  background-image: url('/images/Icon_Purple_Close.svg');
                }

                &:hover {
                  &::after {
                    background-image: url('/images/Icon_White_Close.svg');
                  }
                }
              }
            }
          }
        }
      }
    }

    .is-cta {
      width: 40%;
      margin-left: auto;
      margin-right: 0;
      border: 0;
      overflow: hidden;
      margin-top: 50px;
      // padding-left: 20px;
      .image {
        height: 350px;
        position: relative;
        left: 30px;
        display: block;

        img {
          max-width: none;
          height: 100%;
          display: block;
          width: auto;
          border-radius: 0 0 0 50px;
          transform: none;
        }
      }

      .content {
        background-color: $cochlear-yellow;
        opacity: 0.9;
        width: 90%;
        position: absolute;
        top: 50%;
        left: 0;
        height: calc(100% - 60px);
        transform: translateY(-50%);
        border-radius: 0px 0px 40px 0;

        .cta {
          border: 0;
          width: 170px;
          background-color: #ffffff;
          margin: 29px;
          border-radius: 30px;
          padding: 10px 0 10px 40px;

          &::after {
            left: 123px;
            top: 16px;
          }

          &:hover {
            background-color: $link-colour;
            color: #ffffff;
          }
        }
      }

      .cm-image-block-link {
        min-height: 400px;

        & > a {
          width: 100%;
          padding-right: 0;
          left: 0;
          padding-left: 0;

          & > img {
            position: absolute;
            width: auto;
            // padding-right: 44px;
            border-radius: 0;
            height: 100%;
          }
        }

        img,
        .img {
          padding-left: 20px;
          height: 100%;
          position: absolute;
          width: auto;
        }

        .content {
          // left: -20px;
          left: -2px;
          top: 30px;
          max-width: 308px;
          width: 100%;
          border-radius: 0 0 50px 0;
          height: calc(100% - 60px);
          padding-left: 23px;

          h3 {
            padding: 20px 54px 10px 0;
            font-size: 22px;
          }

          p {
            padding: 0 54px 0 0;
            font-size: 28px;
            min-height: 155px;
          }
        }

        .cta {
          margin: 0;

          &::after {
            display: inline-block;
          }
        }
      }

      &:hover {
        //-webkit-transform: perspective(1000px) translate3d(0, 0, 0);
        transform: perspective(1000px) translate3d(0, 0, 0);

        .cta {
          background-color: $link-colour;
          color: #ffffff;

          &::after {
            background-image: url('/images/Icon_White_Chevron.svg');
          }
        }

        h3 a,
        p {
          color: $link-colour;
        }
      }
    }
  }
}

.l-content-column {
  .form-box {
    .sl-list {
      .multi-container {
        display: flex;

        .date-input {
          flex: 0 1 100px;
          padding-right: 30px;
          position: relative;

          &::after {
            content: "/";
            position: absolute;
            top: 7px;
            left: 80px;
          }

          &.year {
            flex: 0 1 125px;
            padding-right: 10px;

            &::after {
              content: "";
            }
          }
        }
        flex: 0 1 50%;
      }
    }
    
    .sl-item {
      .warn {
        margin-top: 20px;
        
        & label {
          margin-top: 10px;
        }
      }

      // offset height of fixed header
      .anchor {
        padding-top: 200px;
        margin-top: -200px;
        position: absolute;
      }
    }
  }
}

// Form submit button spinner (for custom form buttons)
// -------------------------------------------
form.mktform.been-clicked {
  button[type='submit'].cta {
    .lds-ring {
      display: inline-block;
      right: 21px;
      top: 11px;
      position: absolute;
    }

    &::after {
      content: none;
    }
  }
}
// Form submit button spinner
// -------------------------------------------
button[type='submit'].cta,
.downloadBox {
  &.been-clicked {
    .lds-ring {
      display: inline-block;
      right: 21px;
      top: 11px;
      position: absolute;
    }

    &::after {
      content: none;
    }
  }

  .lds-ring {
    display: none;
    position: relative;
    width: 29px;
    height: 29px;
  }

  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    margin: 2px;
    border: 2px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }

  &:active {
    .lds-ring div {
      border-color: $link-colour transparent transparent transparent;
    }
  }

  @media screen and (min-width: 64em) {
    &:hover {
      .lds-ring div {
        border-color: $link-colour transparent transparent transparent;
      }
    }
  }

  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }

  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }

  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

.l-one-column {
	@include fadeIn(0.2s, 1);
}

.article-page {
	.l-content-column {
		min-height: 500px;
		margin-bottom: 30px;
	}
}

.video-page {
	.is-full-width {
		margin-bottom: 0;

		.cm-media-container {
			figure {
				margin: 0 auto 40px auto;
			}
		}
	}
}

.Campaign {
	.l-content-column {
		margin-top: 25px;
	}
}

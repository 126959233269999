.professionals-popup {
	width: 280px;
	margin: 0 auto 0 -140px;
	position: absolute;
	top: 140px;
	left: 50%;
	z-index: 4;
	background-color: $brand-white;
	padding: 20px;

	@media only screen and (min-width: 64em) {
		width: 800px;
		padding: 60px;
		margin: 0 auto 0 -400px;
		top: 240px;
	}

	h2 {
		margin-top: 0;
		border-bottom: 5px solid $cochlear-yellow;
		padding-bottom: 10px;
	}

	.cta-button {
		margin-right: 20px;
	}

	.close-btn {
		background-image: sax(Close, $cool-grey);
		background-repeat: no-repeat;
		background-size: 100%;
		background-color: transparent;
		opacity: 0.5;
		position: absolute;
		transition-duration: 0.15s;
		transition-property: color,background-color,border-color;
		top: 20px;
		right: 20px;
		width: 15px;
		height: 15px;
		border: 0;
		cursor: pointer;

		&:hover {
			opacity: 1;
		}
	}
}

@media print {
	.top-nav,
	.nav,
	.nav-onscreen,
	.mobile-toolbar,
	.social-share,
	.logo,
	.cm-media-container,
	.cta-button,
	.is-full-width,
	.related-block,
	.global-footer,
	.breadcrumbs {
		display: none;
	}

	.notExpanded {
		display: block !important;
		margin-bottom: 40px !important;
	}

	img {
		max-width: 200px;
	}

	@page {
		margin: 0.5cm;
	}
}

.footnotes-info {
  .last-updated-date{
    div{
      font-style: oblique
    }
  }
  .effective-date,
  .footnotes {
    margin-bottom: 15px;
  }
  .last-updated-date,
  .effective-date {
    &> span > div {
      display: inline-block;
    }
  }
}

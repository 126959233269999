// ==========================================================================
// COLOURS
// ==========================================================================

$cochlear-yellow: #FDC82F;
$cochlear-black: #1E1E1E;
$cochlear-purple: #3F1482;
$cochlear-error: #EB0606;
$cool-grey: #56565A;
$mid-grey: #CCCCCC;
$light-grey: #EFEFEF;
$brand-white: #FFFFFF;
$lighter-grey: #F6F6F6;
$link-colour: #3F1482; // Eggplant
$link-colour-hover: #672AC5;

// ==========================================================================
// FONTS
// ==========================================================================
$font-header: 'BlissPro-Regular','SourceHanSans-Regular', 'Trebuchet MS', 'Gill Sans', 'Helvetica Neue', Arial, sans-serif;
$font-primary: 'BlissPro-Light','SourceHanSans-Light', 'Trebuchet MS', 'Gill Sans', 'Helvetica Neue', Arial, sans-serif;
$font-primary-bold:'BlissPro-Regular', 'SourceHanSans-Regular', 'Trebuchet MS', 'Gill Sans', 'Helvetica Neue', Arial,
	sans-serif;
$font-primary-light: 'BlissPro-Light','SourceHanSans-Light', 'Trebuchet MS', 'Gill Sans', 'Helvetica Neue', Arial,
	sans-serif;
$font-special: 'Knockout-30', sans-serif;

// ==========================================================================
// SCREENS
// ==========================================================================
//TODO: UTILISE THESE SCREEN SIZE VARIABLES
$tablet: 'only screen and (min-width: 64em)';

// ==========================================================================
// ANIMATION
// ==========================================================================

//$site-base-transition: 300ms;
//$form-base-transition: 500ms;

// ==========================================================================
// SIZES
// ==========================================================================

// $nav-offscreen-width: 270px;

// ==========================================================================
// FORMS
// ==========================================================================

.simple-header {
	border-top: 5px solid $cochlear-yellow;
	padding: 0;
	width: 100%;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

	.logo {
		float: none;
		width: 115px;
		margin: 0 auto;
		padding: 15px 0 5px 0;
		display: block;

		img {
			width: 100%;
			height: auto;
		}
	}
}

.mobile-header {
	padding: 15px 0;
  border-top: 5px solid $cochlear-yellow;
  
  &.is-hidden {
    @include mobile-menu-background-visibility;
  }

	@media only screen and (min-width: 64em) {
		display: none;
	}

	.mobile-logo {
		float: none;
		width: 130px;
		margin: 0 auto;
		padding: 0;
		display: block;

		img {
			width: 100%;
			height: auto;
			display: block;
		}
	}
}

.professionals-page {
	.mobile-header {
		border-top: 5px solid $link-colour;
	}
}

/* ----------- COLOURS ----------- */
$cochlear-yellow: #fdc82f;
$cool-grey: #56565a;
$mid-grey: #cccccc;
$light-grey: #efefef;
$link-colour: #3f1482; // Eggplant

html {
	-webkit-tap-highlight-color: rgba(239, 239, 239, 0.5);
}

/* ----------- FONTS ----------- */


$font-header: 'BlissPro-Regular',"SourceHanSans-Regular","Trebuchet MS","Gill Sans","Helvetica Neue",Arial,sans-serif;
$font-primary: 'BlissPro-Light',"SourceHanSans-Light","Trebuchet MS","Gill Sans","Helvetica Neue",Arial,sans-serif;
$font-primary-bold: 'BlissPro-Regular',"SourceHanSans-Regular","Trebuchet MS","Gill Sans","Helvetica Neue",Arial,sans-serif;
$font-primary-light: 'BlissPro-Light',"SourceHanSans-Light","Trebuchet MS","Gill Sans","Helvetica Neue",Arial,sans-serif;
$font-special: "Knockout-30",sans-serif;

body {
	font-family: $font-primary;
}
h1, h2, h3, h4, h5 {
	font-family: $font-header;
	font-weight: 600;
	color: $cool-grey;
	a {
		color: $cool-grey;
	}
}

// Breakpoints
// ----------------------------
@mixin bp-small-min {
  @media only screen and (min-width: 36em) {
    @content;
  }
}

@mixin bp-large-min {
	@media only screen and (min-width: 64em) {
		@content;
	}
}

@mixin bp-large-max {
	@media only screen and (max-width: 63.9375em) {
		@content;
	}
}

@mixin bp-extralarge-min {
	@media only screen and (min-width: 77.75em) {
		@content;
	}
}

// Transitions
// ----------------------------
@mixin transition($args...) {
	transition: $args;
}

// Variables
// ----------------------------

$max-container-width: 1180px;
$map-height: 586px;
$list-fluid-width: 34%;
$list-max-width: 400px;
$map-fluid-width: 66%;
$map-max-width: 780px;
$lpadding-lg-padding: 0 20px;
$lpadding-lg-width: 1024px;
$lpadding-xlg-padding: 0 36px;
$lpadding-xlg-width: 1180px;

// Base
// ----------------------------
.clinicFinder {
	p {
		&.results-text{
			font:400 16px/1.5 $font-header;
			text-align: left;
			color: rgba(86, 86, 90, 0.7);
		}
	}
}


// Buttons
// ----------------------------
.clinicFinder {
	// TODO: see what is already being used for these styles in the codebase
	a, button {

		&.btn {
			text-align: center;
			font:700 18px/21px $font-primary;
			display:block;
			padding:13px 23px 14px 29px;
			border-radius: 25px;
			text-decoration: none;
			text-indent: 6px;
			border-width: 1px;
			border-style: solid;
			width:100%;
			@include transition(background .3s ease);
			@include bp-large-min {
				width:auto;
				display: inline-block;
			}
			&:after {
				content: "";
				-webkit-transform: rotate(-90deg);
				-ms-transform: rotate(-90deg);
				transform: rotate(-90deg);
				width: 24px;
				height: 24px;
				position: relative;
				display: inline-block;
				margin: -7px 0 0 5px;
				top: 6px;
				left: 0;
				background-size: 100% 100%;
				@include transition(left .3s ease);
			}
			&:hover, &:active {
				cursor: pointer;
				&:after{
					left: 5px;
				}
			}
			&:disabled {
				cursor:not-allowed;
				opacity: .8;
				&:hover,
				&:active{
					&:after{
						left: 0;
					}
				}
			}
			&.btn--primary {
				&.btn--purple {
					color:#fff;
					background-color:$link-colour;
					border-color:$link-colour;
					&:after{
						background-image: sax(Chevron, white);
					}
					&:hover, &:active {
						background-color:#672ac5;
						border-color:#672ac5;
					}
					&:disabled {
						&:hover,
						&:active{
							background-color:$link-colour;
							border-color:$link-colour;
						}
					}
				}
				&.btn--yellow {
					color:$link-colour;
					background-color:$cochlear-yellow;
					border-color:$cochlear-yellow;
					&:after{
						background-image: sax(Chevron, $cochlear-purple);
					}
					&:hover, &:active {
						background-color:#ffb000;
						border-color:#ffb000;
					}
					&:disabled {
						&:hover,
						&:active{
							background-color:$cochlear-yellow;
							border-color:$cochlear-yellow;
						}
					}
				}
			}
			&.btn--secondary {
				&.btn--purple {
					color:$link-colour;
					background-color:transparent;
					border-color:$link-colour;
					&:after{
						background-image: sax(Chevron, $cochlear-purple);
					}
					&:hover, &:active {
						padding:12px 22px 13px 28px;
						border-width:2px;
					}
					&:disabled {
						&:hover,
						&:active{
							padding:13px 23px 14px 29px;
							border-width:1px;
						}
					}
				}
			}
		}

		// Search map area button
		&.btn-search-map-area {
			display:none;
			@include bp-large-min {
				display:block
			}
			position: absolute;
			left: 50%;
			bottom: 0;
			transform: translateX(-50%);
			margin-bottom: 24px;
			border:0;

			// google maps button style
			// -------------------------------------------

			background:#fff;
			padding:13px 16px;
			box-shadow:0 2px 5px -1px rgba(0, 0, 0, 0.3);
			color:#4285F4;
			cursor:pointer;
			font: 500 12px/1.1 "Helvetica Neue", Arial, sans-serif;
			text-transform:uppercase;
			text-decoration: none;
			&:hover{
				background-color:#F7F7F7
			}

		}

		&.btn-search-my-location {
			z-index: 0;
			position: absolute;
			bottom: 187px;
			right: 0px;
			transition: background-color 0.3s ease;
			margin-right: 10px;
			background-color: #fff;
			display: block;
			box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
			border-radius: 2px;
			width: 40px;
			height: 40px;
			overflow: hidden;
			border: 0;
			cursor: pointer;

			&:before {
				content: "";
				margin: 0 0 0 6px;
				height:18px;
				width:18px;
				display:block;
				background-image: sax(Target, #666666);
				background-repeat: no-repeat;
				background-size: 18px 18px;
				background-position: -1px -1px;
			}
			&:hover{
				&:before {
					// background-position: -36px 0;
					background-image: sax(Target, #000000);
				}
			}
			&.active {
				&:before {
					// background-position: -144px 0;
					// background-image: sax(Target, #000000);
				}
			}
		}

		// &.btn-search-my-location {
		// 	position: absolute;
		// 	right: 10px;
		// 	bottom: 127px;
		// 	margin-top: 8px;
		// 	padding:0;
		// 	border:0;

		// 	// google maps button style
		// 	// -------------------------------------------

		// 	background-color: #fff;
		// 	border-radius: 2px;
		// 	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
		// 	display: block;
		// 	width: 29px;
		// 	height: 29px;
		// 	overflow: hidden;
		// 	cursor: pointer;
		// 	@include transition(background-color .3s ease);
		// 	&:before {
		// 		content: "";
		// 		margin: 0 0 0 6px;
		// 		height:18px;
		// 		width:18px;
		// 		display:block;
		// 		background-image: sax(Location, white);
		// 		background-repeat: no-repeat;
		// 		background-size: 180px 18px;
		// 		background-position: 0 0;
		// 	}
		// 	&:hover{
		// 		&:before {
		// 			background-position: -36px 0;
		// 		}
		// 	}
		// 	&.active {
		// 		&:before {
		// 			background-position: -144px 0;
		// 		}
		// 	}
		// }
	}
}

// Layout
// -------------------------------------------------
.clinicFinder { // lives in the container FindAClinic
	background-color: #f1f0ef; // matches cochlear background

	@include bp-large-min {
		padding-bottom: 46px;
	}

	.l-padding {
		width: 100%;
		padding: 0;
		@include bp-large-min {
			width: $lpadding-lg-width;
			padding: $lpadding-lg-padding;
		}
		@include bp-extralarge-min {
			width: $lpadding-xlg-width;
			padding: $lpadding-xlg-padding;
		}

	}
	.title-banner {
    padding: 40px 20px;

    h1,
    p {
      color: white;
    }
  }

	.clinicFinder__clinicContainer { // wrapper for the list, filter, details and map
		max-width: $max-container-width; // changes for layout
		margin:0 auto;
		background: #fff;
		&:after { // clearfix
			content: "";
			display: table;
			clear: both;
		}
		@include bp-large-min {
			height: $map-height;
			margin:80px auto 34px; // changes for Layout
			overflow:hidden;
			&.showFilters__filters-active {
				.clinicFinder__clinicResults {
					display: -webkit-flex;
					display: flex;
					flex-flow: column nowrap;
				}
			}
		}
	}

	.clinicFinder__clinicResults {
		@include bp-large-min {
			width:$list-fluid-width;
			max-width:$list-max-width; // changes for layout
			float:right;
			clear:right;
			height: $map-height;
			position:relative;
		}
	}

	.clinic-list {
		@include bp-large-min {
			display: -webkit-flex;
			display: flex;
			flex-flow: column nowrap;
			height: $map-height;
		}
		&.filters-open {
			> ul, > .no-clinics {
				display:none;
			}
			height:auto;
			@include bp-large-min {
				display: block;
			}
		}
		.no-clinics {
			padding:20px 20px 40px;
			@include bp-large-min {
				p {
					margin-right: 20%;
				}
			}
		}

		ul {
			padding:0;
			margin:0;
			list-style: none;
			@include bp-large-min {
				// height: $list-height;
				-webkit-flex: 1;
				-ms-flex: 1;
				flex: 1;
				overflow-y: scroll;
				background:#fff;
			}
			&.active {
				li {
					display:none;
					&.show {
						display:block;
						background:#fff;
						.li-inner {
							display:none;
						}
					}
				}
			}
			> li {
				margin:0;
				border-top:1px solid #ddd;
				&:first-child {
					border-top: none;
				}
				.clinic-details-view  {
					display:none;
					&.show {
						display:block;
					}
				}
			}
		}
	}

	.clinicFinder__filterPanel {
		background: #fff;
		z-index: 1;
		width: 100%;
		position: relative;
		display: none;
		@include bp-large-min {
			display: block;
		}
		&.active {
			display:block;
			@include bp-large-min {
				-webkit-flex: 1;
				-ms-flex: 1;
				flex: 1;
				.filterList{
					left:0;
				}
			}
		}
		.filterList {
			@include transition(left .3s ease);
			padding:35px 20px;
			@include bp-large-min {
				position:absolute;
				top:0;
				left:$list-max-width; /*changes for Layout*/
			}
			.filterList__group {
				margin-top: 35px;
				&:first-child {
					margin-top:0;
				}
				// main category labels
				.form-input > label,
				> label {
					&:first-child {
						font: 700 18px/1.56 $font-header;
						text-align: left;
						color: $cool-grey;
						margin-bottom: 24px;
						display: block;
					}
				}
				label { //radio and checkbox
					font-size:15px;
					color:$cool-grey;
				}
			}
			button {
				position: relative;
				margin-top:40px;
			}
		}
	}

	.clinicFinder__clinicMap {
		height: calc( 100vh - 125px);
    max-height: 586px;
		position: relative;  // needed to contain 'search map area' map, which is positioned absolute
		@include bp-large-min {
			width:$map-fluid-width;
			max-width:$map-max-width; // changes for layout
			float:left;
			display:block!important;
			height:100%;
		}
	}

	.clinicFinder__filter-chip-container {
    // padding: 20px 20px 10px;
    padding: 10px 10px 5px;
    border-bottom: 1px solid #efefef;

    .filter-chip {
      position: relative;
      height: 31px;
      background-color: #efefef;
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 10px;
      padding: 0px 29px 0px 11px;
      text-align: center;
      cursor: pointer;

      // .btn-close {
      //   display: inline-block;
      //   width: 10px;
      //   height: 10px;
      //   background: url(/images/Icon_Purple_Close.svg);
      //   background-size: contain;
      // }
      .btn-close {
        position: absolute;
        display: inline-block;
        width: 15px;
        height: 15px;
        top: 7px;
        right: 7px;
        overflow: hidden;

        &:hover {
          &::before,
          &::after {
            background: #3f1482;
          }
        }

        // --- Close Icon CSS START ---
        &::before,
        &::after {
          content: '';
          position: absolute;
          height: 2px;
          width: 100%;
          top: 50%;
          left: 0;
          margin-top: -1px;
          background: #3f1482;
        }

        &::before {
          transform: rotate(45deg);
        }

        &::after {
          transform: rotate(-45deg);
        }
        // &.black {
        //   &::before, &::after {
        //     height: 8px;
        //     margin-top: -4px;
        //   }
        // }
        // --- Close Icon CSS END ---
      }
    }
  }

}

// Components
// ----------------------------

%search-input-font {
	font: 400 36px/1.21 $font-primary;
	@include bp-large-min {
		font: 400 38px/1.13 $font-primary;
	}
}

.clinicFinder {
	// search bar container at the top
	.clinicFinder__searchContainer {
		background-color: #2a2a2a;
		background-color: rgba(30, 30, 30, 0.95);
		.search-inner {
			.label-wrapper {
				float: left;
				> label {
					font: 700 42px/1.24 $font-primary;
					text-align: left;
					color: #ffffff;
					span {
						display:none;
					}
					@include bp-large-min {
						font: 700 38px/1.13 $font-primary;
						span {
							display:inline;
						}
					}
				}
			}
			.input-wrapper {
				clear: left;
				float: none; /* key */
				width: auto; /* key */
				overflow: hidden; /* key */
				padding-top: 23px;
				position: relative;
				@include bp-large-min {
					padding-top: 0;
					clear:none;
				}
				> input {
					@extend %search-input-font;
					text-align: left;
					color: #ffffff;
					width: 100%;
					background: transparent;
					border: 0 none;
					border-bottom: 1px solid $mid-grey;
					padding-right:30px; // so text doesnt cover the search or clear icon
					text-overflow: ellipsis;
					&:focus,
					&:hover,
					&:active,
					&:valid {
						border-bottom: 1px solid #fff;
						outline:0;
					}
				}
				.yellow-underline {
					@extend %search-input-font;
					width: auto;
					display: inline-block;
					position: absolute;
					overflow-x:auto;
					overflow-y:hidden;
					bottom:0;
					left:0;
					height:0;
					border-bottom: 3px solid $cochlear-yellow;
					top: auto;
				}
				.searchbar-icon {
					width:26px;
					height:26px;
					position: absolute;
					background-color: transparent;
					background-repeat:no-repeat;
					background-size: 100% 100%;
					bottom:10px;
					right:0;
					display:block;
					&.search-icon {
						background-image: sax(Search, white);
					}
					&.reset-icon {
						border:0 none;
						background-image: sax(Close, white);
					}
				}
			}
		}
	}

	.clinic-list {

		ul {

			@include bp-large-min {
				// add buffer for scroll arrow so it doesnt always cover the
				// last item in a list even when the area has been scroll to the bottom
				li.scroll-item {
					border-top:0;
					&.filter-active {
						display:none;
					}
				}
			}

			.scroll-bottom {
				display:none;
				@include bp-large-min {
					display:block;
				}
				position: absolute;
				background: linear-gradient(transparent, white);
				width: 100%;
				height: 40px;
				bottom: 0;
				z-index: 1;
				cursor: pointer;
				opacity: 1;
				visibility: visible;
				transition: 0.3s;
				.scroll-arrow {
					position: absolute;
					width: 24px;
					height: 24px;
					bottom: 5px;
					left: 0;
					right: 0;
					margin-left: auto;
					margin-right: auto;
					background-image: sax(Chevron, $cochlear-purple);
					z-index: 1;
					transition: 0.3s;
				}
				&:hover {
					.scroll-arrow {
						bottom: 10px;
					}
				}
			}
			&.at-bottom {
				.scroll-bottom {
					opacity: 0;
					visibility: hidden;
				}
			}

			> li > div:focus,
			> li > div:hover {
				outline:0;
				.li-inner {
					border-left:5px solid $cochlear-yellow;
					cursor:pointer;
				}
			}

		}

		.li-inner {
			padding:20px 40px 15px 15px;
			border-left:5px solid transparent;
			position:relative;
			&:after {
				content: "";
				background-image: sax(Chevron, $cochlear-purple);
				-webkit-transform: rotate(-90deg);
				-ms-transform: rotate(-90deg);
				transform: rotate(-90deg);
				width: 24px;
				height: 24px;
				position: absolute;
				top: calc(50% - 12px);
				right: 13px;
				background-size: 100% 100%;
			}
		}

		.clinic-list-title-bar {
			background: #555;
			color:#fff;
			padding:20px 23px 17px 20px;
			font:700 18px/1.56 $font-header;
			&:after {
				content: "";
				display: table;
				clear: both;
			}
			&.return {
				padding:20px 23px 17px 38px;
				position: relative;
				cursor: pointer;
				&:after {
					content: "";
					background-image: sax(Chevron, white);
					-webkit-transform: rotate(90deg);
					-ms-transform: rotate(90deg);
					transform: rotate(90deg);
					width: 24px;
					height: 24px;
					position: absolute;
					top: calc(50% - 12px);
					left: 13px;
					background-size: 100% 100%;
				}
			}
			.filter-label {
				font-weight: 400;
				font-family: $font-primary;
				text-decoration:underline;
				float: right;
				cursor: pointer;
				padding:0;
				background:none transparent;
				border:0;
			}
			.product-list {
				font-size:16px;
				line-height: 1.45;
				font-weight: normal;
			}
		}

	}
}

%clinic-detail-text {
	position: relative;
	font: 400 18px/1.56 $font-header;
	color: $link-colour;
	text-decoration: underline;
	padding-left:45px;
}
%clinic-detail-icon {
	content: "";
	background-image: sax(Location, $cochlear-yellow);
	width: 24px;
	height: 29px;
	position: absolute;
	top: 0;
	left: 0;
	background-size: 100% 100%;
}

.clinicListing {
	.clinicListing__clinic-name {
		font: 700 22px/1.27 $font-header;
		color: $cool-grey;
		margin-bottom:10px;
	}
	.clinicListing__clinic-product-offers {
		font: 400 16px/1.5 $font-header;
		color: rgba(86, 86, 90, 0.7);
		margin-bottom:15px;
	}
	.clinicListing__clinic-address p {
		font: 400 18px/1.56 $font-header;
		color: $cool-grey;
		position:relative;
		padding-left:34px;
		&:before {
			@extend %clinic-detail-icon;
		}
	}
}

.clinicDetails {
	padding:30px 0 60px;
	@include bp-large-min {
		padding-top:20px;
		padding-left:30px;
		padding-right:30px;
		.btn {
			min-width:230px;
		}
	}
	.clinicDetails--padding {
		padding-left:20px;
		padding-right:20px;
		@include bp-large-min {
			padding-left:0;
			padding-right:0;
		}
	}
	.clinicDetails--dark-border {
		border-top:1px solid #dfdfdf;
		border-bottom:1px solid #e6e6e6;
		@include bp-large-min {
			border-bottom: 0;
			border-top:1px solid #f1f1f1;
		}
	}
	.clinicDetails--no-bottom-border {
		border-bottom:0;
	}
	.clinicDetails__inner-title {
		margin-bottom:10px;
		display: -webkit-flex;
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		@include bp-large-min {
			margin-bottom:3px;
		}
		.clinicDetails__clinic-name {
			font: 700 28px/1.21 $font-header;
			color: $cool-grey;
			-webkit-flex: 0 1 auto;
			-ms-flex: 0 1 auto;
			flex: 0 1 auto;
			@include bp-large-min {
				font-size: 22px;
				line-height: 1.27;
			}
		}
		.clinicDetails__clinic-distance {
			-webkit-flex: 0 1 auto;
			-ms-flex: 0 1 auto;
			flex: 0 1 auto;
			font: 400 18px/1.56 $font-header;
			color: $cool-grey;
			margin-left:10px;
		}
	}
	.clinicDetails__clinic-product-offers {
		font: 400 16px/1.5 $font-header;
		color: rgba(86, 86, 90, 0.7);
		margin-bottom:20px;
	}
	.clinicDetails__clinic-btn-book {
		padding-bottom:20px;
		border-bottom: 1px solid #f8f8f8;
		@include bp-large-min {
			border-bottom: 0;
		}
	}
	.clinicDetails__clinic-address,
	.clinicDetails__clinic-phone,
	.clinicDetails__clinic-email,
	.clinicDetails__clinic-website {
		padding-top:18px;
		padding-bottom:18px;
		.clinicDetails__contact-link {
			@extend %clinic-detail-text;
			@include bp-large-min {
				padding-left:33px;
			}
		}
	}
	.clinicDetails__clinic-address {
		border-top:1px solid #f4f4f4;
		border-bottom: 1px solid #e7e7e7;
		@include bp-large-min {
			padding-bottom:23px;
			border-bottom: 0;
			border-top:1px solid #f1f1f1;
		}
		p {
			@extend %clinic-detail-text;
			@include bp-large-min {
				padding-left:33px;
			}
			&:before {
				@extend %clinic-detail-icon;
				height: 24px;
				top:1px;
			}
			a {
				color:$link-colour
			}
		}
	}
	.clinicDetails__clinic-phone {
		@include bp-large-min {
			padding-top:12px;
			padding-bottom:14px;
		}
		.clinicDetails__contact-link {
			&:before {
				@extend %clinic-detail-icon;
				background-image: sax(Phone, $cochlear-yellow);
				height: 24px;
			}
		}
	}
	.clinicDetails__clinic-email {
		@include bp-large-min {
			padding-top:12px;
			padding-bottom:14px;
		}
		.clinicDetails__contact-link {
			&:before {
				@extend %clinic-detail-icon;
				background-image: sax(Mail, $cochlear-yellow);
				width: 24px;
				height: 24px;
			}
		}
	}
	.clinicDetails__clinic-website {
		@include bp-large-min {
			padding-top:12px;
			padding-bottom:14px;
		}
		.clinicDetails__contact-link {
			&:before {
				@extend %clinic-detail-icon;
				background-image: sax(Website, $cochlear-yellow);
				width: 24px;
				height: 24px;
			}
		}
	}
}

// Clinic Search Input Field
// -------------------------
#clinic-search-input {
  left: 0;
  box-sizing: border-box;
  border: 1px solid transparent;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  width: calc(100% - 40px);
  height: 50px;
  font-size: 18px;
  padding: 0 50px 0 12px;
  // border-radius: 3px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  outline: none;
  text-overflow: ellipsis;
  // background: url(/images/Icon_Purple_Search.svg) no-repeat right 15px center,
	//   white;
	background: sax(Search, #672ac5) no-repeat right 15px center,
	white;
	opacity: 1;

  &:focus {
    outline: none;
    border-bottom: 3px solid #fdc82f;
  }

  @media only screen and(orientation: landscape) {
    // opacity: $mobile-searchfield-opacity;
    opacity: 0.75;

    &:focus {
      opacity: 1;
    }
  }

  @include bp-small-min {
    opacity: 1;
  }
}


// Loading spinner (Find a clinic section loader)
// -------------------------------------------
.lds-dual-ring {
	display:block;
	width: 46px;
	height: 46px;
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -23px 0 0 -23px;
	&:after {
		content: " ";
		display: block;
		width: 46px;
		height: 46px;
		margin: 1px;
		border-radius: 50%;
		border: 5px solid $mid-grey;
		border-color: $mid-grey transparent $mid-grey transparent;
		animation : lds-dual-ring 1.2s linear infinite;
	}
}
@keyframes lds-dual-ring {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}


/* Customize the label (the container) */
.radio-container {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	/* Hide the browser's default radio button */
	input {
		position: absolute;
		opacity: 0;

		&:checked {
			/* When the radio button is checked, add a blue background */
			& ~ .radiopoint {
				// background-color: #2196F3;
				border: 1px solid $link-colour;
				/* Show the indicator (dot/circle) when checked */
				&:after {
					display: block;
				}
			}
		}

		&:focus {
			& ~ .radiopoint {
				border: 1px solid $link-colour;
			}
		}
	}
	/* Style the indicator (dot/circle) */
	.radiopoint {
		&:after {
			top: 6.5px;
			left: 6.5px;
			width: 7.5px;
			height: 7.5px;
			border-radius: 50%;
			background: $link-colour;
		}
	}

	&:hover {
		color: $link-colour !important;
		input {
			& ~ .radiopoint {
				/* On mouse-over, add a grey background color */
				// background-color: #ccc;
				border: 1px solid $link-colour;
			}
		}
	}
}

html {
	-webkit-tap-highlight-color: rgba(239, 239, 239, 0.5);
}

.l-content-column {
	position: relative;
	max-width: 730px;
	width: 100%;
	margin: 0 auto 60px auto;
}

.l-article {
  overflow: hidden;

  &.is-hidden {
    @include mobile-menu-background-visibility;
  }
}

.l-padding {
	position: relative;
}

.content-card {
	&.is-alt-bg {
		background-color: $light-grey;
	}
}

@media only screen and (min-width: 77.75em) {
	.l-container,
	.l-padding {
		width: 1180px;
	}
}

body {
	font-family: $font-primary;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	font-family: $font-header;
	font-weight: 600;
	color: $cool-grey;

	a {
		color: $cool-grey;
	}
}

h1,
.h1 {
	font-size: 36px;
	line-height: 42px;
}

h2,
.h2 {
	font-size: 30px;
	line-height: 36px;
}

h3,
.h3 {
	font-size: 26px;
	line-height: 34px;
}

h4,
.h4 {
	font-size: 22px;
	line-height: 28px;
}

h5,
.h5 {
	font-size: 18px;
	line-height: 26px;
}

h6,
.h6 {
	font-size: 16px;
	line-height: 22px;
}

@media only screen and (min-width: 64em) {
	h1,
	.h1 {
		font-size: 38px;
		line-height: 44px;
	}

	h2,
	.h2 {
		font-size: 32px;
		line-height: 38px;
	}

	h3,
	.h3 {
		font-size: 28px;
		line-height: 32px;
	}
}

p,
li {
	font-family: $font-primary;
	font-size: 18px;
	line-height: 28px;
	color: $cool-grey;
}

a:focus,
a:hover {
	color: $link-colour;
}

/* ----------- WIDGETS ----------- */
.video-embed {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}

.video-embed iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* ---------- ELEMENTS ----------- */
select {
	appearance: none;
	text-indent: 1px;
	text-overflow: '';

	&::-ms-expand {
		display: none;
	}
}

input[type='search'] {
	&::-ms-clear,
	&::-ms-reveal {
		display: none;
		width: 0;
		height: 0;
	}
}

/* Text meant only for screen readers. */
.screen-reader-text {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute !important;
	width: 1px;
	word-wrap: normal !important;
}

/* No script */
.page-warning {
	font-size: 14px;
	line-height: 1.4286;
	background-color: $cochlear-black;
	color: $brand-white;
	text-align: center;
	padding: 5px 0;
}

/* Full page no scrolling */
.no-scroll {
	
	#main {
		height: 100vh;
		overflow: hidden;

		// Tweak for windows browsers which have elements offset by the scrollbars (WIP)
		// nav#nav {
		// 	padding-right: 17px;
		// 	.top-nav {
		// 		width: calc(100vw + 17px);
		// 		padding-right: 34px;
		// 	}
		// }
	}
}

/* Style the buttons that are used to open and close the accordion panel */

.page-navigation {
	padding: 0;
	top: 0;
	width: 100%;
	background: $brand-white;

	@media only screen and (min-width: 64em) {
		border-bottom: 0;
		background-color: $light-grey;

		.languageSelector {
			position: absolute;
			right: 10px;
			top: 10px;
		}

		&.is-sticky-header {
			padding-top: 80px;

			.languageSelector {
				position: absolute;
				right: 10px;
				top: 5px;
			}
		}
	}

	.primary-navigations {
		display: block;
		position: fixed;
		top: 100vh;
		background: $brand-white;
		z-index: 2;
		transition: top 0.5s;
		height: 100vh;
		padding-top: 10px;

		@media only screen and (min-width: 64em) {
			position: relative;
			top: 0;
			height: auto;
			transform: translateZ(0);
			box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
			padding: 0;
			float: none;
			background: $brand-white;

			@keyframes MenuShow {
				from {
					top: -100px;
				}

				to {
					top: 0;
				}
			}

			&.is-sticky {
				position: fixed;
				width: 100%;
				animation-name: MenuShow;
				animation-duration: 0.4s;
				animation-timing-function: ease-in-out;

				.logo {
					height: auto;
					margin: 10px 10px 0 10px;

					img {
						width: 65px;
						height: 55px;
					}
				}
			}
		}

		@media only screen and (min-width: 77.75em) {
			padding-right: 0;
		}

		.logo {
			float: none;
			width: 130px;
			margin: 0 auto;
			padding: 0;

			@media only screen and (min-width: 64em) {
				height: 85px;
				width: 95px;
				float: left;
				margin: 15px 14px;
				display: block;

				img {
					width: 100%;
					height: auto;
				}
			}
		}

		.l-padding {
			padding: 0;
			margin: 0 auto;

			&::before {
				display: none;
			}

			&::after {
				display: none;
			}

			@media only screen and (min-width: 64em) {
				padding: 0 36px;
				height: auto;
			}
		}

		.f-jc-sb {
			display: flex;
			justify-content: space-between;
		}

		.pri-navs {
			display: flex;
			justify-content: flex-end;
			align-items: flex-end;

			.pri-nav {
				padding: 5px 15px;
			}
		}

		a.nav-link {
			font-weight: bold;
			font-size: 14px;
			text-decoration: none;
			color: #777;
			transition: none;

			&.active {
				border-bottom: 2px solid #fdc82f;
			}

			&:hover,
			&:active {
				border-bottom: 2px solid #fdc82f;
			}
		}
	}
}

.aside-languageSelector {
	.language-container {
		margin: auto 25px;

		.dropdown {
			width: 100%;

			.dropdown-toggle {
				width: 100%;
				padding: 3px 30px 3px 30px;
				margin: auto 0;
				word-wrap: break-word;
				text-align: center;

				@media screen and (min-width: 34.3em) {
					font-size: 16px;
					font-weight: 700;
				}
			}

			.countries_list {
				min-width: 0 !important;

				& a {
					font-size: 12px;

					@media screen and (min-width: 34.3em) {
						font-size: 16px;
					}
				}
			}
		}
	}
}

.secondary-navigations {
	background: $brand-white;
	display: block;
	position: fixed;
	top: 100vh;
	transition: top 0.5s;

	@media only screen and (min-width: 64em) {
		position: relative;
		top: 0;
		height: auto;
		transform: translateZ(0);
		padding: 0;
		float: none;
		background: $brand-white;
	}

	.sec-navs {
		display: flex;
		align-items: center;
		justify-content: flex-end;

		.sec-nav {
			padding: 10px 5px;
		}
	}

	a.nav-link {
		font-size: 14px;
		text-decoration: none;
		color: #777;

		&.active {
			border-bottom: 2px solid #fdc82f;
		}
	}
}

.secondary-navigations-bottom {
	background: $brand-white;

	.sec-navs-middle {
		display: flex;
		align-items: center;
		justify-content: center;

		.sec-nav {
			padding: 10px 5px;
		}
	}

	a {
		font-size: 14px;
		text-decoration: none;
		color: #777;

		&.active {
			border-bottom: 2px solid #fdc82f;
		}
	}
}

.aside-container {
	background: #fff;
	width: 75%;
	height: 100%;
	position: fixed;
	top: 0;
	bottom: 0;
	right: -75%;
	transition-duration: 0.3s;
	overflow: scroll;
	background-color: #2f2c2b;

	&.aside-show {
		transform: translateX(-100%);
		z-index: 10;
	}

	.aside-avatar {
		width: 123px;
		height: auto;
		padding: 9px 15px 8px;
		display: block;
		margin-left: auto;
		margin-right: auto;
	}

	.aside-navigation {
		margin: 0;
		padding: 0;

		.aside-navigation-item {
			margin-top: 0px;
			padding: 20px 25px;
			border-top: 1px solid #404040;
			border-bottom: 1px solid #262626;

			&.active {
				border-left: 6px solid #fdc82f;
				padding: 20px 25px 20px 19px;
			}

			a {
				font-size: 12px;
				color: #fff;
			}
		}
	}
}

.language-container {
	text-align: right;
	position: relative;
	max-width: 1170px;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;

	a {
		text-decoration: none;
		color: #1e1e1e;
	}

	ul li {
		list-style: none;
	}

	&:before {
		display: table;
		content: ' ';
		box-sizing: border-box;
	}

	&:after {
		clear: both;
		display: table;
		content: ' ';
		box-sizing: border-box;
	}

	.dropdown {
		text-align: right;
		display: inline-block;
		position: relative;

		.btn {
			display: inline-block;
			margin: auto 0 auto 15px;
			padding: 3px 30px 3px 45px;
			border-radius: 14px;
			border: 1px solid #ccc;
			font-size: 12px;
			font-weight: bold;
			background-color: white;
			font-family: 'Trebuchet MS', Arial, sans-serif;

			&:hover {
				background-color: #e6e6e6;
				border-color: #adadad;
			}

			.caret {
				display: inline-block;
				margin-left: 2px;
				width: 0;
				height: 0;
				vertical-align: middle;
				border-top: 4px dashed;
				border-right: 4px solid transparent;
				border-left: 4px solid transparent;
				color: #1e1e1e;
			}
		}

		.countries_list {
			max-height: 325px;
			min-width: 290px;
			width: 100%;
			box-sizing: border-box;
			overflow: auto;
			text-align: left;
			padding: 5px 0;
			margin: 2px 0 0;
			background-color: #fff;
			white-space: nowrap;
			border: 1px solid rgba(0, 0, 0, 0.15);
			border-radius: 4px;
			box-shadow: 0 6px 12px rgba(0, 0, 0, 0.18);
			position: absolute;
			top: 100%;
			right: 0;
			z-index: 1000;

			& li {
				margin-bottom: 7px;
				font-size: 14px;

				a {
					text-decoration: none;
					margin-left: 15px;
					position: relative;
					overflow-x: hidden;
					display: block;
					padding: 3px 20px;
					clear: both;
					font-weight: 400;
					line-height: 1.42857143;
					color: #333;
					white-space: nowrap;
					overflow-x: hidden;
					font-family: 'Trebuchet MS', Arial, sans-serif;
				}

				.active {
					background-color: #f5f5f5;
				}
			}
		}
	}
}

@keyframes MainShow {
	from {
		right: 0%;
	}

	to {
		right: 75%;
	}
}

#main {
	position: relative;
	right: 0%;

	&.main-side-show {
		right: 75%;
		animation: MainShow;
		animation-duration: 0.3s;
		animation-timing-function: ease-in-out;
	}
}
//rtl-styles
div#root > div[dir='rtl'] {
	.page-navigation {
		.languageSelector {
			position: absolute;
			left: 10px;
			top: 10px;
		}
		&.is-sticky-header {
			.languageSelector {
				position: absolute;
				left: 10px;
				top: 5px;
			}
		}

		.language-container {
			text-align: left;

			.countries_list {
				text-align: right;
			}
		}
	}

	.aside-container {
		left: -75%;
		right: auto;

		&.aside-show {
			transform: translateX(100%);
			z-index: 10;
		}
	}

	@keyframes MainShowRTL {
		from {
			left: 0%;
		}

		to {
			left: 75%;
		}
	}

	#main {
		position: relative;
		right: auto;
		left: 0%;

		&.main-side-show {
			left: 75%;
			animation: MainShowRTL;
			animation-duration: 0.3s;
			animation-timing-function: ease-in-out;
		}
	}
}

.listing {
	.content-card {
		.img-container {
			img {
				margin: 0 0 0 50%;
				transform: translateX(-50%);
				height: 100%;
				width: auto;
				top: 0;

				@media screen and (min-width: 64em) {
					margin: 0;
				}
			}
		}
	}

	.listing-filter {
		border-bottom: 2px solid #CCCCCC;
		margin-bottom: 30px;

		@media screen and (min-width: 64em) {
			margin-bottom: 60px;
			display: flex;
			flex-flow: row wrap;
			align-content: flex-start;
			justify-content: flex-start;
			align-items: flex-end;
			padding-bottom: 20px;
		}

		.select {
			background-image: sax(Chevron, $cochlear-purple);
			background-repeat: no-repeat;
			background-position: calc(100% - 5px) 50%;
			background-color: $brand-white;
			padding: 0 30px 0 10px;
			background-size: 20px 20px;
			border: 1px solid #DDDDDD;
			border-radius: 0;
			line-height: 2.5;

			&:focus {
				background-color: white;
				border-color: $link-colour;
			}

			@media screen and (min-width: 64em) {
				width: 100%;
			}
		}

		.listing-filter-col {
			margin: 0 20px 20px 0;
			display: block;

			label {
				font-size: 16px;
				line-height: 1.875;
				display: block;
				font-weight: 700;
				margin: 0 0 5px;
				text-align: left;
				color: #000;
				clear: both;
			}

			@media screen and (min-width: 64em) {
				margin: 0 20px 0 0;

				&:nth-child(1) {
					width: 20%;
				}

				&:nth-child(2) {
					width: 26%;
				}

				&:nth-child(3) {
					width: 29%;
				}

				&:nth-child(4) {
					width: 183px;
					margin-right: 0;

					button {
						width: 100%;
						text-align: center;
					}
				}
			}

			@media screen and (min-width: 75.77em) {
				margin: 0 20px 0 0;

				&:nth-child(2) {
					width: 29%;
				}
			}
		}
	}

	.dropdown-trigger {
		color: $cochlear-purple;
		font-weight: 700;
		font-size: 18px;
		display: block;
		width: 100%;
		border-bottom: 1px solid $mid-grey;
		font-family: $font-primary-bold;
		padding-bottom: 10px;
		margin-bottom: 20px;

		&:after {
			@include chevron(0deg, $cochlear-purple);
			right: 25px;
		}

		@media screen and (min-width: 64em) {
			display: none;
		}
	}

	.dropdown-trigger-check[type='checkbox'] {
		display: none;

		& + .dropdown-trigger + .listing-filter {
			display: none;

			@media screen and (min-width: 64em) {
				display: flex;
			}
		}

		&:checked + .dropdown-trigger + .listing-filter {
			display: block;

			@media screen and (min-width: 64em) {
				display: flex;
			}
		}

		&:checked + .dropdown-trigger:after {
			@include chevron(180deg, $cochlear-purple);
			right: 25px;
		}
	}

	.category-heading {
		margin: 30px 0 20px 0;

		&:first-child {
			margin-top: 0;
		}

		@media screen and (min-width: 64em) {
			margin: 60px 0 40px 0;

			&:first-child {
				margin-top: 0;
			}
		}
	}

	.no-results-found {
		margin: 50px 0 200px 0;

		p {
			font-family: $font-header;
			font-size: 28px;
			font-weight: 600;
			line-height: 32px;
		}
	}

	.listing-content-container {
		@media screen and (min-width: 64em) {
			display: flex;
			flex-flow: row wrap;
			align-content: flex-start;
			align-items: stretch;
			margin: 0 -18px;

			.content-card {
				width: 29.8%;
				flex: 0 1 auto;
				padding: 0;
				margin: 0 18px 40px 18px;
				height: auto;
			}

			&.has-4-items {
				.content-card {
					width: 21.85%;

					.content-container,
					.description {
						padding-bottom: 0;
					}
				}
			}
		}
	}

	&.listing-filter-on-left {
		.category-heading {
			display: none;
		}

		@media screen and (min-width: 64em) {
			display: flex;

			.filter-on-left {
				width: 30%;
				padding-right: 20px;

				@media screen and (min-width: 64em) {
					margin-bottom: 0;
				}
			}

			.single-column {
				width: 70%;
				padding-left: 40px;

				.content-card {
					width: 100%;
				}

				.cta-button {
					@media screen and (min-width: 64em) {
						margin: auto;
					}
				}
			}
		}

		.listing-filter {
			border: 0;

			.listing-filter-col {
				@media screen and (min-width: 64em) {
					width: 100%;
					margin-top: 20px;
				}
			}

			.select {
				width: 100%;
			}

			.cta-button {
				width: 100%;
				text-align: center;
			}

			.listing-filter-col {
				margin-right: 0;
			}
		}
	}

	.single-column {

		margin-bottom: 70px;

		@media screen and (min-width: 64em) {
			margin: 0 0 70px 0;
		}

		.content-card {
			background-color: $light-grey;
			border: 0;
			display: flex;
			border-bottom-right-radius: 50px;
			min-height: 250px;
			flex-direction: column;
			margin-bottom: 80px;
			margin-top: 80px;

			@media screen and (min-width: 64em) {
				flex-direction: row;
				margin: 0 0 30px 0;
			}

			.img-container {
				width: 200px;
				height: 200px;
				margin-left: -100px;
				margin-top: -25px;
				position: relative;
				left: 50%;
				overflow: hidden;

				@media screen and (min-width: 64em) {
					margin-left: -26px;
					margin-top: 25px;
					left: 0;
				}

				img {
					margin: 0 0 0 50%;
					display: block;
					height: 100%;
					width: auto;

					@media screen and (min-width: 64em) {
						margin: 0;
					}
				}
			}

			.content-container {
				margin-left: 20px;
				padding: 20px 10px 20px 0;
				text-align: left;
				background-color: transparent;

				@media screen and (min-width: 64em) {
					margin-left: 50px;
					width: 470px;
				}

				.title {
					margin-bottom: 5px;
					padding: 0;
					border: 0;
				}

				.subtitle {
					font-size: 16px;
					line-height: 24px;
					margin-bottom: 10px;
					padding: 0;
					border: 0;
				}

				.description {
					margin: 0 0 15px 0;
					padding: 0;
					border: 0;
				}

				.cta-button {

					width: 100%;
					margin-bottom: 20px;
					text-align: center;
					border: 0;
					background-color: $cochlear-purple;
					color: $brand-white;
					border-radius: 40px;

					@media screen and (min-width: 64em) {
						width: auto;
					}

					&:after {
						margin: -4px 0 0;
						right: -5px;
						top: 5px;
						position: relative;
						display: inline-block;
						background-image: sax(Chevron, $brand-white);
					}
				}
			}

			&:hover {
				@media screen and (min-width: 64em) {
					transform: none;
					box-shadow: none;
				}

				.cta-button {
					transition: all 0.2s ease-in-out;
					background-color: #672AC5;

					&:after {
						transition: all 0.2s ease-in-out;
						right: -10px;
					}
				}
			}
		}
	}
}

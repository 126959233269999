.numbered-list {
	margin: 20px 0 40px 0;

	img {
		max-width: 100%;
		height: auto;
		display: block;
		margin: 0 auto;
	}

	ol {
		margin-left: 3px;
		counter-reset: list;
		padding-left: 40px;

		li {
			position: relative;
			border-bottom: 1px solid $mid-grey;
			padding-bottom: 20px;
			line-height: 25px;

			&::before {
				position: absolute;
				content: counters(list, '.', decimal-leading-zero) ' ' '';
				left: -43px;
				padding-top: 15px;
				font-size: 30px;
				font-family: $font-special;
				line-height: 0;
				counter-increment: list;
				color: $cool-grey;
				width: 41px;
				height: 40px;
				background-color: $brand-white;
			}

			h4 {
				font-size: 22px;
				font-weight: bold;
				margin: 0;
			}

			p {
				margin: 0;
				line-height: 25px;
			}
		}
	}
}

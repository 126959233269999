.about-row {
	background-color: $brand-white;
	padding: 30px 0;

	@media only screen and (min-width: 64em) {
		padding: 60px 0;
	}


	.about-container {
	  	padding-top: 20px;
	  	img{
			width: 100%;
			height: 100%;
	  	}
	  	h1{
		font-size: 32px;
		font-family: $font-primary-bold;
	  }
	  	.about-content {
			margin-top: 20px;
			font-size: 18px;
			margin-bottom: 20px;
			p{
		  		line-height: 35px;
			}
			.scWebEditInput.scEnabledChrome[contentEditable="true"] {
		  	width: 100%;
			}
		}

	  	@media only screen and (min-width: 64em) {
		  padding-top: 20px;
		  position: relative;
			  img{
				width: 100%;
				height: 100%;
			  }
			  h1{
				font-size: 32px;
				font-family: $font-primary-bold;
				position: absolute;
				top: 12%;
				left: 36%;
			  }
			  .about-content {
				margin-top: 20px;
				padding-left: 36%;
				font-size: 18px;
				margin-bottom: 20px;
				p{
				  line-height: 44px;
				}
				.scWebEditInput.scEnabledChrome[contentEditable="true"] {
				  width: 100%;
				}

			  }
			}

		}


	&.is-secondary {
		background-color: $light-grey;
	}
}

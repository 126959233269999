main > header.simple-header {
  // .nav-onscreen {
  //   display: none;
  // }
  border-top: 0;
  @media only screen and (min-width: 64em) {
    // height: 115px;
    border-top: 5px solid $cochlear-yellow;
    .nav-onscreen {
      display: block;
    }
  }
}

.cm-rich-text {
	// margin: 0 0 60px 0;
	// overflow: scroll;	// What is this for?

	@media only screen and (min-width: 64em) {
		overflow: auto;
	}

	h2,
	.h2 {
		margin-bottom: 0;
	}

	table {
		border-top: 1px solid $mid-grey;
		border-bottom: 1px solid $mid-grey;

		th {
			background-color: $cochlear-yellow;
			border-top: 1px solid $cochlear-yellow;
			border-left: 1px solid $cochlear-yellow;
			border-right: 1px solid $cochlear-yellow;
		}

		tr {
			td {
				border-left: 1px solid $mid-grey;
				border-right: 1px solid $mid-grey;
			}
		}
	}

	img {
		height: auto !important;
	    width: 100% !important;
	}

	li,
	p {
		a:not(.cta-button) {
			color: $link-colour;
		}
	}

	.key-points {
		border-top: 5px solid $cochlear-yellow;
		border-bottom: 2px solid $mid-grey;
		margin: 0 0 40px 0;
		padding: 20px 0;

		h3 {
			font-size: 22px;
			line-height: 25px;
		}

		p {
			font-size: 18px;
			line-height: 25px;
		}
	}

	.breakout {
		margin: 30px 0 20px 0;
		padding-bottom: 40px;
		padding-top: 40px;
		position: relative;
		background-color: $light-grey;

		&::before {
			content: '';
			position: absolute;
			width: 999%;
			height: 100%;
			left: -999%;
			background-color: $light-grey;
			z-index: -1;
			margin-top: -40px;
		}

		h3 {
			border-bottom: 5px solid $cochlear-yellow;
			padding-bottom: 20px;
			margin-bottom: 20px;
		}

		p {
			padding-right: 70px;
		}
	}

	ol,
	ul {
		margin-left: 15px;
		padding: 0;

		li {
			font-size: 18px;
			margin: 0 0 15px 15px;
			padding-left: 15px;

			h4 {
				font-size: 16px;
				font-weight: bold;
				margin: 0;
			}

			p,
			li {
				margin: 0;
				font-size: 18px;
				line-height: 25px;
			}
		}
	}
}

blockquote {
	border-left: solid $cochlear-yellow 5px;
	padding-left: 20px;
	font-size: 18px;
	font-family: $font-special;
	color: $cool-grey;

	p {
		font-size: 22px;
		font-family: $font-special;
		color: $cool-grey;
		line-height: 1.3;

		&:nth-child(2) {
			font-size: 18px;
			margin-top: 10px;
		}
	}

	span {
		font-family: $font-special;
	}
}

.landing-page,
.home-page {
	.cm-rich-text {
		@media only screen and (min-width: 64em) {
			div > p {
				font-size: 24px;
				line-height: 36px;
			}
		}
	}
}

.product-page {
	.cm-rich-text {
		padding-top: 40px;
	}
}

// first element which happens to be a p element,
// inside the first div element which happens to be a .cm-rich-text element
.l-content-column div:first-child.cm-rich-text p:first-child {
	margin-top: 40px;
}
.country-redirect {
	padding-top: 15px;
	background-color: $light-grey;

	.content-container {
		position: relative;

		p {
			padding-right: 30px;
		}
	}

	.cta-button {
		padding: 0 40px 0 30px;
	}

	.dropdown-container {
		display: flex;
		margin: 0;
		padding: 0;
		padding-bottom: 15px;
		border-left: 0;
		background-color: transparent;

		.dropdown {
			flex-grow: 2;
			margin-right: 15px;
		}

		.close-button {
			background-image: sax(Close, $cochlear-purple);
			width: 24px;
			height: 24px;
			background-repeat: no-repeat;
			background-size: 100% 100%;
			border: 0;
			background-color: transparent;
			position: absolute;
			top: 0;
			right: 0;
			cursor: pointer;
		}
	}
}

@media screen and (min-width: 64em) {
	.country-redirect {
		padding: 10px 0;

		p {
			float: left;
			margin: 0;
			line-height: 52px;
		}

		.dropdown-container {
			float: right;
			padding: 0;
			position: relative;

			.dropdown {
				display: inline-block;
				width: auto;
				min-width: 280px;
				margin-right: 15px;
			}

			.cta-button {
				margin-right: 39px;
			}

			.close-button {
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}
}

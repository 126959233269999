.disclaimer {
	border-top: 1px solid $mid-grey;
	padding: 40px 0;
	font-size: 14px;
	font-family: 'BlissPro-Regular', 'Trebuchet MS', 'Gill Sans', 'Helvetica Neue', Arial, sans-serif;
	color: $cool-grey;

	h2,
	h6 {
		font-weight: 900;
		font-size: 16px;
		font-family: 'BlissPro-Regular', 'Trebuchet MS', 'Gill Sans', 'Helvetica Neue', Arial,
			sans-serif;
		color: $cool-grey;
		line-height: normal;
	}

	p,
	li {
		font-size: 14px;
		font-family: 'BlissPro-Regular', 'Trebuchet MS', 'Gill Sans', 'Helvetica Neue', Arial,
			sans-serif;
		color: $cool-grey;
	}

	ol {
		margin-left: 15px;
		padding: 0;

		li {
			margin: 0 0 15px 0;
			padding-left: 15px;
		}
	}

	a {
		word-break: break-all;

		@media screen and (min-width: 64em) {
			display: inline;
		}
	}

	&.noTopBorder {
		border-top: 0 solid $mid-grey;
	}

  	&:last-child:not(.hastopline){
	  border-top: 0 solid $mid-grey;
	}
}

.cm-media-container {
	figcaption {
		font-size: 16px;
		color: $cool-grey;
		border: 0;
		padding: 0 0 0 20px;
		border-left: 5px solid $cochlear-yellow;

		p {
			font-family: $font-special;
			font-size: 22px;
			line-height: 1.3;

			&:nth-child(2) {
				font-size: 18px;
				margin-top: 10px;
			}
		}
	}

	.image-embed img {
		width: 100%;
		height: auto;
	}
}

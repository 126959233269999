.clinic-finder-mini {
	margin: 0 auto 20px auto;
	padding: 20px;
	position: relative;
	background-color: $light-grey;

	@media only screen and (min-width: 64em) {
		border-radius: 0 0 40px 0;
		padding: 40px 70px 40px 40px;

		&::before {
			content: '';
			position: absolute;
			width: 999%;
			height: 100%;
			left: -999%;
			background-color: $light-grey;
			z-index: -1;
			margin-top: -40px;
		}
	}

	.clinics {
		position: relative;

		ul,
		li {
			margin: 0;
			padding: 0;
			list-style: none;
		}

		ul {

			margin-bottom: 20px;

			@media only screen and (min-width: 64em) {
				min-height: 450px;
				margin: 40px 0;
			}
		}

		li {

			border-top: 1px solid $mid-grey;
			display: flex;
			flex-flow: column nowrap;
			position: relative;

			&:first-child {
				border-top: 0;
			}

			&:before {
				content: " ";
				background-image: sax(Chevron, $cochlear-purple);
				transform: rotate(-90deg);
				width: 24px;
				height: 24px;
				position: absolute;
				top: calc(50% - 12px);
				right: 13px;
				background-size: 100% 100%;
			}

			a {
				display: flex;
				text-decoration: none;
				background-color: $brand-white;

				.clinic-image {
					width: 150px;
					height: 150px;
					position: relative;
					display: none;
					overflow: hidden;

					@media only screen and (min-width: 64em) {
						display: block;
						position: absolute;
					}

					&:before {
						content: '';
						width: 35px;
						height: 42px;
						background-size: 100%;
						background-image: sax(LocationPinFull, $cochlear-purple);
						background-repeat: no-repeat;
						top: 50%;
						left: 50%;
						margin: -20px 0 0 -20px;
						position: absolute;
						display: block;
					}

					img {
						width: 200px;
					}
				}

				.clinic-details {

					padding: 15px 40px 15px 15px;
					border-left: 5px solid transparent;

					@media only screen and (min-width: 64em) {
						margin-left: 150px;
						height: 150px;
					}

					.clinic-details__title {
						font-size: 22px;
						line-height: 1.27;
					}

					.clinic-details__description {
						font-weight: $font-header;
						font-size: 16px;
						line-height: 1.25;
						color: rgba(86, 86, 90, 0.7);
					}

					.clinic-details__contact {
						font-size: 18px;
						position: relative;
						padding-left: 30px;

						p {
							line-height: 1.25;
						}

						&:before {
							content: '';
							width: 25px;
							height: 25px;
							background-size: 100% 100%;
							background-image: sax(Location, $cochlear-yellow);
							display: inline-block;
							position: absolute;
							left: 0;
							top: 1px;
						}
					}
				}

				&:hover {
					.clinic-details {
						border-left-color: $cochlear-yellow;
					}
				}
			}
		}

		.cta-button {
			@include cta-button;
		}

		.secondary {
			background-color: transparent;
			margin-top: 20px;

			@media only screen and (min-width: 64em) {
				margin-top: 0;
			}

			&:hover {
				background-color: $cochlear-purple;
			}
		}

		.tertiary {
			color: $cochlear-purple;
			font-family: $font-primary;
			font-size: 18px;

			@media only screen and (min-width: 64em) {
				margin-right: 20px;
			}

			&:after {
				background-image: sax(Chevron, $cochlear-purple);
			}
		}

		&.loading {
			display: block;
			height: 450px;

			&::after {
				animation: spinner 1.2s linear infinite;
				content: ' ';
				position: absolute;
				top: 50%;
				left: 50%;
				margin: -23px 0 0 -23px;
				display: block;
				width: 46px;
				height: 46px;
				border-radius: 50%;
				border: 5px solid $mid-grey;
				border-color: $mid-grey transparent $mid-grey transparent;
			}
		}
	}

	.no-clinics {
		margin: 40px 0 40px 0;
	}
}

body {
	&.is-sticky {
		margin: 0;
		height: 100%;
		overflow: hidden;
	}
}

.modal-video {
	background-color: rgba(0, 0, 0, 0.9);

	.modal-video-close-btn {
		right: 18px;
		width: 24px;
		height: 24px;
		bottom: calc((50vh + 50%) - 44px);

		&::before,
		&::after {
			margin-top: 0;
		}
	}
}

@media only screen and (min-width: 64em) {
	.modal-video {
		background-color: rgba(0, 0, 0, 0.8);

		.modal-video-close-btn {
			bottom: auto;
			top: -44px;
			right: 0;
		}
	}
}

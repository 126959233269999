.country-select-nav {
	width: 100%;
	position: fixed;
	background-color: white;
	top: 0;
	left: 0;
	z-index: 2;
	padding-bottom: 40px;
	height: calc(100vh - 60px);	
	overflow-y: scroll;
	opacity: 1;
	visibility: visible;
	transition: 0.3s 0.3s;

	@media screen and (min-width: 64em) {
		height: calc(100vh - 164px);		// full page height minus the header height
		padding-bottom: 80px;
		position: relative;
	}

	a {
		text-decoration: none;
	}

	.language {
		display: block;
		font-size: 12px;
		margin-left: 40px;
	}

	.h1 {
		letter-spacing: 1px;
		font-family: $font-header;
	}

	.country-select-title {
		background-color: $cochlear-black;
		padding: 40px 0;

		@media screen and (min-width: 64em) {
			border-radius: 0 0 40px 0;
			padding: 30px 0;
		}

		.h1 {
			color: white;

			@media screen and (min-width: 64em) {
				font-size: 42px;
			}
		}

		.country-exit {
			cursor: pointer;
			display: block;
			height: 25px;
			width: 25px;
			border: 0;
			padding: 0;
			background-color: transparent;
			background-image: sax(Close, white);
			background-size: 100%;
			margin-top: 10px;
			float: right;

			@media screen and (min-width: 64em) {
				height: 20px;
				width: 20px;
				margin-top: 15px;
			}
		}
	}

	.country-select-list {
		padding-top: 40px;

		h3 {
			border-bottom: 4px solid $cochlear-yellow;
			margin-bottom: 15px;
			padding-bottom: 18px;
			clear: both;
		}

		ul {
			list-style: none;
			padding: 0;

			@media screen and (min-width: 64em) {
				display: flex;
				flex-wrap: wrap;
			}

			li {
				margin-top: 15px;

				@media screen and (min-width: 64em) {
					width: 25%;
					float: left;
					margin: 15px 0;
					flex-basis: 25%;
				}

				.region-flag {
					height: 30px;
					width: 30px;
					float: left;
					margin-right: 10px;
				}
			}
		}
	}
}

.is-sticky-header {
	.country-select-nav {
		@media screen and (min-width: 64em) {
			position: fixed;
			top: 124px;
		}
	}
}

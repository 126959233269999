.contact-block {
  margin-bottom: 40px;

  h2:first-child {
    margin-top:40px;
  }
  .contact-item-container {
    margin: 20px 0;
    @media only screen and (min-width: 64em) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .contact-item {
      padding-bottom: 20px;
      @media only screen and (min-width: 64em) {
        width: 30%;
      }
    }
  }
}

// first element which happens to be a h2 element,
// inside the first div element which happens to be a .contact-block element
.l-content-column div:first-child.contact-block h2:first-child {
	margin-top: 0px;
}